import { UserTypes as Type } from '../../../types/user';

const initialState = {
  forgotData: null,
  loading: false,
  error: null,
};

const ForgotPasswordReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case Type.GET_FORGOT_PASSWORD_REQUEST:
      return { ...state, loading: true, error: null, forgotData: null};
    case Type.GET_FORGOT_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        forgotData: action.payload,
        loading: false,
      };
    case Type.GET_FORGOT_PASSWORD_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false, forgotData: null };
    case Type.RESET_CHANGE_PASSWORD_STATE:
      return { ...initialState };
    default:
      return state;
  }
};

export default ForgotPasswordReducer;