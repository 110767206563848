import { takeEvery, put, call } from 'redux-saga/effects';
import { MenuTypes } from '../../../types/menu';
import {
  getCategoryListRequestFailure,
  getCategoryListRequestSuccess,
} from '../../../actions/menu/category-list'
import { getCategoryList } from '../../../../services/menu';

function* asyncGetCategoryListRequest(action: any): any {
  try {
    const response = yield call(
        getCategoryList
    );
    yield put(getCategoryListRequestSuccess(response));
  } catch (error) {
    yield put(getCategoryListRequestFailure(error));
  }
}

export function* getCategoryListSaga() {
  yield takeEvery(
    MenuTypes.GET_CATEGORY_LIST_REQUEST,
    asyncGetCategoryListRequest,
  );
}
