import { ConfigurationTypes as Type } from '../../types/configuratios';

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const getConfigurationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case Type.GET_CONFIGURATIONS_REQUEST:
      return { ...state, loading: true, error: null, data: null };
    case Type.GET_CONFIGURATIONS_REQUEST_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case Type.GET_CONFIGURATIONS_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false, data: null };
    default:
      return state;
  }
};

export default getConfigurationReducer;
