import { useEffect, useLayoutEffect, useState } from 'react';
import './App.css';
import PublicRoutes from './routes/publicroutes';
import Layout from './layout';
import { useLocation,useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { setLogoutRequest } from './redux/actions/user/login';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { getConfigurationsRequest } from './redux/actions/configuration';
import { displayToast } from './helpers/notification';


function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const { user } = useSelector((state: any) => state.LoginReducer);
  const dispatch = useDispatch();

    useEffect(() =>{
         setTimeout(()=>{
          dispatch(setLogoutRequest());
          displayToast('ERROR', 'Session time out');
          navigate('/');
         },1000 * 60 * 60 * 24)
    },[])

  useEffect(() => {
    dispatch(getConfigurationsRequest());
  }, []);
  useLayoutEffect(() => {
    if (user) {
      setIsUserLoggedIn(true);
    } else {
      setIsUserLoggedIn(false);
    }
  }, [location.pathname, user]);
  return (
    <>
      <ToastContainer />
      {isUserLoggedIn ? <Layout /> : <PublicRoutes />}
    </>
  );
}

export default App;
