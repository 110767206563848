import { UserTypes as Type } from '../../../types/user';

const initialState = {
  user: null,
  loading: false,
  error: null,
};

const LoginReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case Type.GET_LOGIN_REQUEST:
      return { ...state, loading: true, error: null, user: null };
    case Type.GET_LOGIN_REQUEST_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    case Type.GET_LOGIN_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };
    case Type.SET_LOGOUT_REQUEST:
      return { ...initialState };
    default:
      return state;
  }
};

export default LoginReducer;
