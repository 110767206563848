import { displayToast } from "../../../../helpers/notification";
import { StoreSettingTypes as Type } from "../../../types/store";


export function refreshMerchantAccountRequest(id: any, accountId: any) {
  return {
    type: Type.REFRESH_STORE_MERCHANT_ACCOUNT,
    id,
    accountId
  };
}

export function refreshMerchantAccountRequestSuccess(data: any) {
  return {
    type: Type.REFRESH_STORE_MERCHANT_ACCOUNT_SUCCESS,
    payload: data,
  };
}

export function refreshMerchantAccountRequestFailure(error: any) {
    try {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message &&
        error.response.data.message.length > 0
      ) {
        if (Array.isArray(error.response.data.message))
          displayToast('ERROR', error.response.data.message[0]);
        else displayToast('ERROR', error.response.data.message);
      } else {
        displayToast('ERROR', error.message);
      }
    } catch (e) {
      displayToast('ERROR', error.message);
    }
    return {
      type: Type.REFRESH_STORE_MERCHANT_ACCOUNT_FAILURE,
      error: error,
    };
  }