export enum FeaturedContentTypes {
    GET_FEATURED_CONTENT_LIST_REQUEST = 'GET_FEATURED_CONTENT_LIST_REQUEST',
    GET_FEATURED_CONTENT_LIST_REQUEST_SUCCESS = 'GET_FEATURED_CONTENT_LIST_REQUEST_SUCCESS',
    GET_FEATURED_CONTENT_LIST_REQUEST_FAILURE = 'GET_FEATURED_CONTENT_LIST_REQUEST_FAILURE',

    ADD_FEATURED_CONTENT_REQUEST = 'ADD_FEATURED_CONTENT_REQUEST',
    ADD_FEATURED_CONTENT_REQUEST_SUCCESS = 'ADD_FEATURED_CONTENT_REQUEST_SUCCESS',
    ADD_FEATURED_CONTENT_REQUEST_FAILURE = 'ADD_FEATURED_CONTENT_REQUEST_FAILURE',

    UPDATE_FEATURED_CONTENT_REQUEST = 'UPDATE_FEATURED_CONTENT_REQUEST',
    UPDATE_FEATURED_CONTENT_REQUEST_SUCCESS = 'UPDATE_FEATURED_CONTENT_REQUEST_SUCCESS',
    UPDATE_FEATURED_CONTENT_REQUEST_FAILURE = 'UPDATE_FEATURED_CONTENT_REQUEST_FAILURE',

    DELETE_FEATURED_CONTENT_REQUEST = 'DELETE_FEATURED_CONTENT_REQUEST',
    DELETE_FEATURED_CONTENT_REQUEST_SUCCESS = 'DELETE_FEATURED_CONTENT_REQUEST_SUCCESS',
    DELETE_FEATURED_CONTENT_REQUEST_FAILURE = 'DELETE_FEATURED_CONTENT_REQUEST_FAILURE',

    RESET_ADD_FEATURED_CONTENT_REQUEST = 'RESET_ADD_FEATURED_CONTENT_REQUEST',
    RESET_UPDATE_FEATURED_CONTENT_REQUEST = 'RESET_UPDATE_FEATURED_CONTENT_REQUEST',
    RESET_DELETE_FEATURED_CONTENT_REQUEST = 'RESET_UPDATE_FEATURED_CONTENT_REQUEST',
}