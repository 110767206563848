import { displayToast } from '../../../../helpers/notification';
import { FaqTypes as Type } from '../../../types/faq';

export function getFaqsListRequest(body: any, filterData: any) {
  return {
    type: Type.GET_FAQ_LIST_REQUEST,
    body,
    filterData,
  };
}

export function getFaqsListRequestSuccess(data: any) {
  return {
    type: Type.GET_FAQ_LIST_REQUEST_SUCCESS,
    payload: data,
  };
}

export function getFaqsListRequestFailure(error: any) {
  debugger;
  try {
    if (
      error.response &&
      error.response.data &&
      error.response.data.message &&
      error.response.data.message.length > 0
    ) {
      if (Array.isArray(error.response.data.message))
        displayToast('ERROR', error.response.data.message[0]);
      else displayToast('ERROR', error.response.data.message);
    } else {
      displayToast('ERROR', error.message);
    }
  } catch (e) {
    displayToast('ERROR', error.message);
  }
  return {
    type: Type.GET_FAQ_LIST_REQUEST_FAILURE,
    error: error,
  };
}
