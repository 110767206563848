import { displayToast } from '../../../helpers/notification';
import { ImageTypes as Type } from '../../types/image-upload';

export function uploadImageRequest(body: any) {
  return {
    type: Type.UPLOAD_IMAGES_REQUEST,
    body: body,
  };
}

export function uploadImageRequestSuccess(data: any) {
  return {
    type: Type.UPLOAD_IMAGES_REQUEST_SUCCESS,
    payload: data,
  };
}

export function uploadImageRequestFailure(error: any) {
  displayToast('ERROR', 'A server error occurred while uploading the image');
  return {
    type: Type.UPLOAD_IMAGES_REQUEST_FAILURE,
    error: error,
  };
}

export function resetImageUploadState() {
  return {
    type: Type.RESET_UPLOAD_IMAGES_REQUEST,
  };
}
