import { displayToast } from '../../../../helpers/notification';
import { MenuTypes as Type } from '../../../types/menu';

export function menuDataSyncRequest() {
  return {
    type: Type.SYNC_MENU_DATA,
  };
}

export function menuDataSyncRequestSuccess(data: any) {
  return {
    type: Type.SYNC_MENU_DATA_SUCCESS,
    payload: data,
  };
}

export function menuDataSyncRequestFailure(error: any) {
  try {
    if (
      error.response &&
      error.response.data &&
      error.response.data.message &&
      error.response.data.message.length > 0
    ) {
      if (Array.isArray(error.response.data.message))
        displayToast('ERROR', error.response.data.message[0]);
      else displayToast('ERROR', error.response.data.message);
    } else {
      displayToast('ERROR', error.message);
    }
  } catch (e) {
    displayToast('ERROR', error.message);
  }
  return {
    type: Type.SYNC_MENU_DATA_FAILURE,
    error: error,
  };
}

export function resetMenuDataState() {
  return {
    type: Type.RESET_SYNC_MENU_STATE,
  };
}
