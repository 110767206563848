import { takeEvery, put, call } from 'redux-saga/effects';
import { ImageTypes } from '../../types/image-upload';
import {
  uploadImageRequestSuccess,
  uploadImageRequestFailure,
} from '../../actions/image-upload'
import { uploadsImages } from '../../../services/image-upload';

function* asyncUploadImageRequest(action: any): any {
  try {
    const response = yield call(
     uploadsImages,
      action.body,
    );
    yield put(uploadImageRequestSuccess(response));
  } catch (error) {
    yield put(uploadImageRequestFailure(error));
  }
}

export function* uploadImageSaga() {
  yield takeEvery(
    ImageTypes.UPLOAD_IMAGES_REQUEST,
    asyncUploadImageRequest,
  );
}
