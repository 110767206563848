import { takeEvery, put, call } from 'redux-saga/effects';
import { OrderManagementTypes } from '../../types/order';
import {
  getOrdersDetailRequestSuccess,
  getOrdersDetailRequestFailure,
} from '../../actions/order';
import { getOrderDetail } from '../../../services/order';

function* asyncOrderDetailRequest(action: any): any {
  try {
    const response = yield call(getOrderDetail, action.id);
    yield put(getOrdersDetailRequestSuccess(response));
  } catch (error) {
    yield put(getOrdersDetailRequestFailure(error));
  }
}

export function* getOrderDetailSaga() {
  yield takeEvery(
    OrderManagementTypes.GET_ORDERS_DETAIL_REQUEST,
    asyncOrderDetailRequest,
  );
}
