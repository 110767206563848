import React, { useEffect, useState } from 'react';
import './index.css';
import Page from '../../components/page-title';
import Logo from '../../components/logo';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Grid,
  Checkbox,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { getLoginUserRequest } from '../../redux/actions/user/login';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Loader from '../../components/loader';
import axios from 'axios';

const useStyles = makeStyles((theme: any) => ({
  root: {
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: 20,
  },
  rootInput: {
    '& .MuiInput-root.Mui-error .MuiInput-input': {
      borderColor: '#FF3B30',
      color: theme.palette.text.primary,
    },
  },
}));

const Login = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [action, setAction] = useState(false);
  let userEmail = localStorage.getItem('email');
  let userPassword = localStorage.getItem('password');
  let checkRememberMe = localStorage.getItem('REMEMBER_ME');
  const [errorMsg, setErrorMsg] = useState('');
  const [invalid, setInvalid] = useState({
    email: userEmail ? userEmail : false,
    password: userPassword ? userPassword : false,
  });
  const [checked, setChecked] = useState(
    checkRememberMe ? JSON.parse(checkRememberMe) : false,
  );
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const { user, error, loading } = useSelector(
    (state: any) => state.LoginReducer,
  );

  useEffect(() => {
    if (user) {
      localStorage.setItem('accessToken', user.accessToken);
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + user.accessToken;
      navigate('/');
    } else if (error && action) {
      setInvalid({ email: true, password: true });
      setErrorMsg('Entered email and/or password is incorrect.');
    }
  }, [user, error]);

  const validateEmail = (email: string) => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  };

  const validate = (values: any) => {
    setInvalid({ email: false, password: false });
    setErrorMsg('');
    setButtonDisabled(true);

    if (!validateEmail(values.email)) {
      setInvalid({ ...invalid, email: true });
      setErrorMsg('Please enter a valid email address');
      return false;
    } else if (values.password.length < 8) {
      setInvalid({ ...invalid, password: true });
      setErrorMsg('Password must be minimum 8 characters');
      return false;
    } else {
      return true;
    }
  };

  const handleCheckBoxChange = (event: any) => {
    setChecked(event.target.checked);
  };

  const checkData = (email: any, password: any) => {
    setAction(true);
    dispatch(
      getLoginUserRequest({
        email,
        password,
      }),
    );
  };

  return (
    <>
      <Page className="auth-bg" title="Login">
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Logo alignItems="center" />
          <Container className="form" maxWidth="sm">
            <Typography className="auth-header-title" variant="h1">
              Login
            </Typography>
            <Formik
              initialValues={{
                email: userEmail ? userEmail : '',
                password: userPassword ? userPassword : '',
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().max(255).required('Email is required'),
                password: Yup.string()
                  .max(255)
                  .required('Password is required'),
              })}
              onSubmit={async (values) => {
                const { email, password } = values;
                localStorage.setItem('REMEMBER_ME', checked.toString());
                if (checked === true) {
                  localStorage.setItem('email', email);
                  localStorage.setItem('password', password);
                } else {
                  localStorage.removeItem('email');
                  localStorage.removeItem('password');
                }

                if (!validate(values)) {
                  return;
                }
                checkData(email, password);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                isValid,
                dirty,
              }) => (
                <form
                  autoComplete="off"
                  onSubmit={handleSubmit}
                  className="auth-form-class"
                >
                  <Typography variant="h4" className="auth-error">
                    {errorMsg ? errorMsg : ''}
                  </Typography>
                  <Typography
                    style={{ color: invalid.email ? '#dd3030' : '#1e272e' }}
                    variant="h2"
                    className="auth-label"
                  >
                    Email
                  </Typography>
                  <TextField
                    className={classes.rootInput}
                    variant="standard"
                    label={values.email === '' ? 'Enter Email' : ''}
                    error={
                      !userEmail &&
                      Boolean((touched.email && errors.email) || invalid.email)
                    }
                    inputProps={{
                      form: {
                        autocomplete: 'off',
                      },
                      style: { marginLeft: '2px' },
                    }}
                    fullWidth
                    InputProps={{
                      className: 'auth-inputField',
                    }}
                    InputLabelProps={{
                      style: {
                        fontFamily: 'SF-Pro-Regular',
                        fontSize: '16px',
                        fontWeight: 'normal',
                        fontStretch: 'normal',
                        fontStyle: 'normal',
                        lineHeight: 1.5,
                        letterSpacing: '0.2px',
                        color: '#8E8E93',
                        opacity: 100,
                        marginTop: -16,
                      },
                      shrink: false,
                    }}
                    name="email"
                    onBlur={handleBlur}
                    onChange={(event) => {
                      setButtonDisabled(false);
                      handleChange(event);
                      setErrorMsg('');
                      setInvalid({ ...invalid, email: false });
                    }}
                    type="text"
                    value={values.email}
                  />
                  <Typography
                    style={{
                      color: invalid.password ? '#dd3030' : '#1e272e',
                      marginTop: 25,
                    }}
                    variant="h2"
                    className="auth-label"
                  >
                    Password
                  </Typography>
                  <TextField
                    variant="standard"
                    className={classes.rootInput}
                    error={
                      !userPassword &&
                      Boolean(
                        (touched.password && errors.password) ||
                          invalid.password,
                      )
                    }
                    fullWidth
                    InputProps={{
                      className: 'auth-inputField',
                      style: { marginLeft: '2px' },
                    }}
                    InputLabelProps={{
                      style: {
                        fontFamily: 'SF-Pro-Regular',
                        fontSize: '16px',
                        fontWeight: 'normal',
                        fontStretch: 'normal',
                        fontStyle: 'normal',
                        lineHeight: 1.5,
                        letterSpacing: '0.2px',
                        color: '#8E8E93',
                        opacity: 100,
                        marginTop: -16,
                      },
                      shrink: false,
                    }}
                    label={values.password === '' ? 'Enter Password' : ''}
                    name="password"
                    onBlur={handleBlur}
                    onChange={(event) => {
                      setButtonDisabled(false);
                      handleChange(event);
                      setErrorMsg('');
                      setInvalid({ ...invalid, password: false });
                    }}
                    type="password"
                    value={values.password}
                  />
                  <Grid
                    alignItems="center"
                    className="items-div"
                    container
                    direction="row"
                    spacing={3}
                  >
                    <Grid item xs={6}>
                      <Grid container className="items-div">
                        <Checkbox
                          color="success"
                          checked={checked}
                          style={{ padding: 0, fontSize: 10 }}
                          onChange={handleCheckBoxChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                        <Typography
                          variant="h2"
                          className="remember-me"
                          align="left"
                        >
                          Remember Me
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid style={{ textAlign: 'right' }} item xs={6}>
                      <Typography
                        onClick={() => navigate('/forgot-password')}
                        variant="h2"
                        className="forget-password"
                        align="left"
                      >
                        Forgot password?
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box>
                    {loading ? (
                      <div className="loader-del">
                        <Loader />
                      </div>
                    ) : (
                      <Button
                        disabled={
                          !(
                            (userEmail && userPassword) ||
                            (isValid && dirty && !buttonDisabled)
                          )
                        }
                        fullWidth
                        size="large"
                        className={
                          !(
                            (userEmail && userPassword) ||
                            (isValid && dirty && !buttonDisabled)
                          )
                            ? 'auth-button'
                            : 'auth-button-show'
                        }
                        type="submit"
                        variant="contained"
                      >
                        Next
                      </Button>
                    )}
                  </Box>
                </form>
              )}
            </Formik>
          </Container>
        </Box>
      </Page>
    </>
  );
};
export default Login;
