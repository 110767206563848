export enum AdminSettingTypes {
    GET_ADMIN_LIST_REQUEST = 'GET_ADMIN_LIST_REQUEST',
    GET_ADMIN_LIST_REQUEST_SUCCESS = 'GET_ADMIN_LIST_REQUEST_SUCCESS',
    GET_ADMIN_LIST_REQUEST_FAILURE = 'GET_ADMIN_LIST_REQUEST_FAILURE',

    ADD_NEW_ADMIN_REQUEST = 'ADD_NEW_ADMIN_REQUEST',
    ADD_NEW_ADMIN_REQUEST_SUCCESS = 'ADD_NEW_ADMIN_REQUEST_SUCCESS',
    ADD_NEW_ADMIN_REQUEST_FAILURE = 'ADD_NEW_ADMIN_REQUEST_FAILURE',
    RESET_ADD_ADMIN_STATE = 'RESET_ADD_ADMIN_STATE',


    UPDATE_ADMIN_REQUEST = 'UPDATE_ADMIN_REQUEST',
    UPDATE_ADMIN_REQUEST_SUCCESS = 'UPDATE_ADMIN_REQUEST_SUCCESS',
    UPDATE_ADMIN_REQUEST_FAILURE = 'UPDATE_ADMIN_REQUEST_FAILURE',
    RESET_UPDATE_ADMIN_STATE = 'RESET_UPDATE_ADMIN_STATE',

    CHANGE_ADMIN_PASSWORD_REQUEST = 'CHANGE_ADMIN_PASSWORD_REQUEST',
    CHANGE_ADMIN_PASSWORD_REQUEST_SUCCESS = 'CHANGE_ADMIN_PASSWORD_REQUEST_SUCCESS',
    CHANGE_ADMIN_PASSWORD_REQUEST_FAILURE = 'CHANGE_ADMIN_PASSWORD_REQUEST_FAILURE',
    RESET_ADMIN_CHANGE_PASSWORD_STATE = 'RESET_ADMIN_CHANGE_PASSWORD_STATE',

    DELETE_ADMIN_REQUEST = 'DELETE_ADMIN_REQUEST',
    DELETE_ADMIN_REQUEST_SUCCESS = 'DELETE_ADMIN_REQUEST_SUCCESS',
    DELETE_ADMIN_REQUEST_FAILURE = 'DELETE_ADMIN_REQUEST_FAILURE',
    RESET_DELETE_ADMIN_STATE = 'RESET_DELETE_ADMIN_STATE',
    
}