import { takeEvery, put, call } from 'redux-saga/effects';
import { MenuTypes } from '../../../types/menu';
import {
  menuDataSyncRequestSuccess,
  menuDataSyncRequestFailure,
} from '../../../actions/menu/syncMenuData'
import { syncMenuAllData } from '../../../../services/menu';

function* asyncMenuDataSyncRequest(action: any): any {
  try {
    const response = yield call(syncMenuAllData);
    yield put(menuDataSyncRequestSuccess(response));
  } catch (error) {
    yield put(menuDataSyncRequestFailure(error));
  }
}

export function* syncMenuDataSaga() {
  yield takeEvery(
    MenuTypes.SYNC_MENU_DATA,
    asyncMenuDataSyncRequest,
  );
}
