import './index.css';
const Loader = (props: any) => {
  const { className } = props;
  return (
    <div id="loader_container" className={className}>
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loader;
