import { Route, Routes } from 'react-router-dom';
import React, { lazy, Suspense } from 'react';
import { url } from 'inspector';
import { CircularProgress } from '@mui/material';
const Account = lazy(() => import('../pages/account'));
const AdminSettings = lazy(() => import('../pages/admin-settings'));
const UserProfile = lazy(() => import('../pages/admin-settings/user-profile'));
const CustomerReport = lazy(() => import('../pages/customer-report'));
const CustomerDetail = lazy(() => import('../pages/customer-report/detail'));
const FeatureContent = lazy(() => import('../pages/feature-content'));
const PrimaryContent = lazy(
  () => import('../pages/feature-content/primary-content'),
);
const SecondaryContent = lazy(
  () => import('../pages/feature-content/secondary-content'),
);
const GCMembership = lazy(() => import('../pages/gc-membership'));
const HelpSection = lazy(() => import('../pages/help-section'));
const AddEditQuestion = lazy(
  () => import('../pages/help-section/add-edit-question'),
);
const Menu = lazy(() => import('../pages/menu'));
const EditItem = lazy(() => import('../pages/menu/edit-item'));
const NotFound = lazy(() => import('../pages/not-found'));
const OrderManagement = lazy(() => import('../pages/order-management'));
const Referral = lazy(() => import('../pages/referral'));
const Store = lazy(() => import('../pages/store-management'));
const StoreEdit = lazy(() => import('../pages/store-management/edit'));
const OrderDetail = lazy(() => import('../pages/order-management/detail'));
const Refresh = lazy(() => import('../pages/refresh-page'))


const PrivateRoute = () => {
  return (
    <>
      <Suspense
        // fallback={
        //   // <div
        //   //   style={{
        //   //     display: 'flex',
        //   //     height: 'calc(100vh - 30vh)',
        //   //     alignItems: 'center',
        //   //     justifyContent: 'center',
        //   //     background: '#fff',
        //   //     flexDirection: 'column',
        //   //   }}
        //   // >
        //   //   <div
        //   //     style={{
        //   //       backgroundImage: `url(${require('../assests/images/logo.png')})`,
        //   //       backgroundRepeat: 'no-repeat',
        //   //       backgroundPosition: 'center',
        //   //       width: '100%',
        //   //       height: '70px',
        //   //     }}
        //   //   />
        //   //   <CircularProgress />
        //   // </div>
        // }
      >
        <Routes>
          <Route path="/" element={<Menu />} />
          <Route path="/store" element={<Store />} />
          <Route path="/store/update/:id" element={<StoreEdit />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/menu/item-edit/:catid/:itemid" element={<EditItem />} />
          <Route path="/gcmembership" element={<GCMembership />} />
          <Route path="/featuredcontent" element={<FeatureContent />} />
          <Route
            path="/featuredcontent/add/:categoryid"
            element={<PrimaryContent />}
          />
          <Route
            path="/featuredcontent/update/:categoryid/:itemid"
            element={<PrimaryContent />}
          />
          <Route
            path="/featuredcontent/addbestseller/:id"
            element={<SecondaryContent />}
          />
          <Route
            path="/featuredcontent/updatebestseller/:id"
            element={<SecondaryContent />}
          />
          <Route path="/referral" element={<Referral />} />
          <Route path="/help" element={<HelpSection />} />
          <Route path="/help/add" element={<AddEditQuestion />} />
          <Route path="/help/update/:id" element={<AddEditQuestion />} />
          <Route path="/ordermanagement" element={<OrderManagement />} />
          <Route path="/ordermanagement/detail/:id" element={<OrderDetail />} />
          <Route path="/customerreport" element={<CustomerReport />} />
          <Route
            path="/customerreport/detail/:id"
            element={<CustomerDetail />}
          />
          <Route path="/adminsettings" element={<AdminSettings />} />
          <Route path="/adminsettings/add" element={<UserProfile />} />
          <Route path="/adminsettings/update/:id" element={<UserProfile />} />
          <Route path="/account" element={<Account />} />
          <Route path="/refresh" element={<Refresh />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default PrivateRoute;
