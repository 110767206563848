export enum StoreSettingTypes {
  GET_STORE_LIST_REQUEST = 'GET_STORE_LIST_REQUEST',
  GET_STORE_LIST_REQUEST_SUCCESS = 'GET_STORE_LIST_REQUEST_SUCCESS',
  GET_STORE_LIST_REQUEST_FAILURE = 'GET_STORE_LIST_REQUEST_FAILURE',


  GET_CSV_STORE_LIST_REQUEST = 'GET_CSV_STORE_LIST_REQUEST',
  GET_CSV_STORE_LIST_REQUEST_SUCCESS = 'GET_CSV_STORE_LIST_REQUEST_SUCCESS',
  GET_CSV_STORE_LIST_REQUEST_FAILURE = 'GET_CSV_STORE_LIST_REQUEST_FAILURE',
  

  EDIT_STORE_REQUEST = 'EDIT_STORE_REQUEST',
  EDIT_STORE_REQUEST_SUCCESS = 'EDIT_STORE_REQUEST_SUCCESS',
  EDIT_STORE_REQUEST_FAILURE = 'EDIT_STORE_REQUEST_FAILURE',

  STORE_DETAIL_REQUEST = 'STORE_DETAIL_REQUEST',
  STORE_DETAIL_REQUEST_SUCCESS = 'STORE_DETAIL_REQUEST_SUCCESS',
  STORE_DETAIL_REQUEST_FAILURE = 'STORE_DETAIL_REQUEST_FAILURE',

  RESET_EDIT_STORE_STATE = 'RESET_EDIT_STORE_STATE',

  CREATE_STORE_MERCHANT_ACCOUNT = 'CREATE_STORE_MERCHANT_ACCOUNT',
  CREATE_STORE_MERCHANT_ACCOUNT_SUCCESS = 'CREATE_STORE_MERCHANT_ACCOUNT_SUCCESS',
  CREATE_STORE_MERCHANT_ACCOUNT_FAILURE = 'CREATE_STORE_MERCHANT_ACCOUNT_FAILURE',

  SYNC_STORE_DATA = 'SYNC_STORE_DATA',
  SYNC_STORE_DATA_SUCCESS = 'SYNC_STORE_DATA_SUCCESS',
  SYNC_STORE_DATA_FAILURE = 'SYNC_STORE_DATA_FAILURE',

  RESET_SYNC_STORE_STATE = 'RESET_SYNC_STORE_STATE',


  REFRESH_STORE_MERCHANT_ACCOUNT = 'REFRESH_STORE_MERCHANT_ACCOUNT',
  REFRESH_STORE_MERCHANT_ACCOUNT_SUCCESS = 'REFRESH_STORE_MERCHANT_ACCOUNT_SUCCESS',
  REFRESH_STORE_MERCHANT_ACCOUNT_FAILURE = 'REFRESH_STORE_MERCHANT_ACCOUNT_FAILURE'
}
