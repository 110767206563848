import { AdminSettingTypes as Type } from '../../../types/adminsetting';

const initialState = {
  changePassword: null,
  loading: false,
  error: null,
};

const ChangeAdminPasswordReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case Type.CHANGE_ADMIN_PASSWORD_REQUEST:
      return { ...state, loading: true, error: null, changePassword: null };
    case Type.CHANGE_ADMIN_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        changePassword: action.payload,
        loading: false,
      };
    case Type.CHANGE_ADMIN_PASSWORD_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };
      case Type.RESET_ADMIN_CHANGE_PASSWORD_STATE:
        return { ...initialState };
    default:
      return state;
  }
};

export default ChangeAdminPasswordReducer;