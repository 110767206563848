import { displayToast } from "../../../../helpers/notification";
import { StoreSettingTypes as Type } from "../../../types/store";


export function editStoreDetailsRequest(body: any, id: any) {
  return {
    type: Type.EDIT_STORE_REQUEST,
    id,
    body
  };
}

export function editStoreDetailsRequestSuccess(data: any) {
  return {
    type: Type.EDIT_STORE_REQUEST_SUCCESS,
    payload: data,
  };
}

export function editStoreDetailsRequestFailure(error: any) {
  try {
    if (
      error.response &&
      error.response.data &&
      error.response.data.message &&
      error.response.data.message.length > 0
    ) {
      if (Array.isArray(error.response.data.message))
        displayToast('ERROR', error.response.data.message[0]);
      else displayToast('ERROR', error.response.data.message);
    } else {
      displayToast('ERROR', error.message);
    }
  } catch (e) {
    displayToast('ERROR', error.message);
  }
  return {
    type: Type.EDIT_STORE_REQUEST_FAILURE,
    error: error,
  };
}

export function resetStateUpdateStore(){
  return {
      type: Type.RESET_EDIT_STORE_STATE,
    };
}