import { all } from 'redux-saga/effects';
import { getLoginUserSaga } from './user/login';
import { forgotPasswordSaga } from './user/forgot_password';
import { changePasswordSaga } from './user/reset_password';
import { checkTokenExpirySaga } from './user/check_expiry';
import { getMenuListSaga } from './menu/list';
import {syncMenuDataSaga} from './menu/syncMenuData';
import { getFeaturedContentListSaga } from './featuredcontent/list';
import { updateMenuListSaga } from './menu/update';
import { uploadImageSaga } from './upload-image';
import { getCategoryListSaga } from './menu/category-list';
import { getItemListSaga } from './menu/item-list';
import { addFeaturedContentSaga } from './featuredcontent/add';
import { getConfigurationsSaga } from './configuration';
import { updateFeaturedContentSaga } from './featuredcontent/update';
import { getAdminListSaga } from './adminsetting/list';
import { addNewAdminSaga } from './adminsetting/add';
import { updateAdminSaga } from './adminsetting/update';
import { changeAdminPasswordSaga } from './adminsetting/changepassword';
import { deleteAdminSaga } from './adminsetting/delete';
import { deleteFeaturedContentSaga } from './featuredcontent/delete';
import { addFaqSaga } from './faq/add';
import { updateFaqSaga } from './faq/update';
import { topicsFaqSaga } from './faq/topics';
import { getStoreListSaga } from './store/list';
import { getCSVStoreListSaga } from './store/csv';
import { getReferralListSaga } from './referral';
import { getFAQListSaga } from './faq/list';
import { deleteFaqSaga } from './faq/delete';
import { storeDetailSaga } from './store';
import { storeEditSaga } from './store/edit';
import {storeSyncDataSaga} from './store/syncStoreData'
import {storeCreateMerchantAccountSaga} from './store/createmerchantaccount';
import {storeRefreshMerchantAccountSaga} from './store/refreshmerchantaccount';
import { getCustomerReportListSaga } from './customerreport/list';
import { reOpenCustomerReportTicketSaga } from './customerreport/reopenticket';
import { closeCustomerReportTicketSaga } from './customerreport/closeticket';
import { getGCmembershipListSaga } from './gc-membership';
import { getOrdersListSaga } from './order/list';
import { getOrderDetailSaga } from './order';
export default function* rootSaga() {
  yield all([
    getLoginUserSaga(),
    forgotPasswordSaga(),
    changePasswordSaga(),
    getMenuListSaga(),
    updateMenuListSaga(),
    syncMenuDataSaga(),
    uploadImageSaga(),
    getFeaturedContentListSaga(),
    getCategoryListSaga(),
    getItemListSaga(),
    addFeaturedContentSaga(),
    getConfigurationsSaga(),
    updateFeaturedContentSaga(),
    getAdminListSaga(),
    addNewAdminSaga(),
    updateAdminSaga(),
    changeAdminPasswordSaga(),
    deleteAdminSaga(),
    deleteFeaturedContentSaga(),
    getAdminListSaga(),
    addFaqSaga(),
    updateFaqSaga(),
    topicsFaqSaga(),
    getStoreListSaga(),
    getReferralListSaga(),
    getFAQListSaga(),
    deleteFaqSaga(),
    storeDetailSaga(),
    storeSyncDataSaga(),
    getCSVStoreListSaga(),
    storeEditSaga(),
    storeCreateMerchantAccountSaga(),
    storeRefreshMerchantAccountSaga(),
    getCustomerReportListSaga(),
    reOpenCustomerReportTicketSaga(),
    closeCustomerReportTicketSaga(),
    getGCmembershipListSaga(),
    checkTokenExpirySaga(),
    getOrdersListSaga(),
    getOrderDetailSaga()
  ]);
}
