import { displayToast } from "../../../../helpers/notification";
import { StoreSettingTypes as Type } from "../../../types/store";


export function syncStoreData() {
  return {
    type: Type.SYNC_STORE_DATA
  };
}

export function syncStoreDataSuccess(data: any) {
  return {
    type: Type.SYNC_STORE_DATA_SUCCESS,
    payload: data,
  };
}

export function syncStoreDataFailure(error: any) {
    try {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message &&
        error.response.data.message.length > 0
      ) {
        if (Array.isArray(error.response.data.message))
          displayToast('ERROR', error.response.data.message[0]);
        else displayToast('ERROR', error.response.data.message);
      } else {
        displayToast('ERROR', error.message);
      }
    } catch (e) {
      displayToast('ERROR', error.message);
    }
    return {
      type: Type.SYNC_STORE_DATA_FAILURE,
      error: error,
    };
  }

  export function resetStateSyncStore(){
    return {
        type: Type.RESET_SYNC_STORE_STATE,
      };
  }