import { takeEvery, put, call } from 'redux-saga/effects';
import { StoreSettingTypes } from '../../../types/store';
import {
    refreshMerchantAccountRequestSuccess,
    refreshMerchantAccountRequestFailure,
} from '../../../actions/store/refreshmerchantaccount';
import { refreshStoreStripeId } from '../../../../services/store';

function* asyncRefreshMerchantAccountRequest(action: any): any {
  try {
    const response = yield call(refreshStoreStripeId, action.id, action.accountId);
    yield put(refreshMerchantAccountRequestSuccess(response));
  } catch (error) {
    yield put(refreshMerchantAccountRequestFailure(error));
  }
}

export function* storeRefreshMerchantAccountSaga() {
  yield takeEvery(
    StoreSettingTypes.REFRESH_STORE_MERCHANT_ACCOUNT,
    asyncRefreshMerchantAccountRequest,
  );
}
