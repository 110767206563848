
import { ReferralTypes as Type } from '../../types/referral';
const initialState = {
  referralList: [],
  loading: false,
  error: null,
};

const ReferralListReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case Type.GET_REFERRAL_LIST_REQUEST:
      return { ...state, loading: true, error: null, referralList: [] };

    case Type.GET_REFERRAL_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        referralList: action.payload,
        loading: false,
      };
    case Type.GET_REFERRAL_LIST_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };
    default:
      return state;
  }
};

export default ReferralListReducer;