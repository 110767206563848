import { takeEvery, put, call } from 'redux-saga/effects';
import { AdminSettingTypes } from '../../../types/adminsetting';
import {
  deleteAdminSuccess,
  deleteAdminFailure,
} from '../../../actions/adminsetting/delete'
import { deleteAdmin } from '../../../../services/admin';

function* asyncDeleteAdminRequest(action: any): any {
  try {
    const response = yield call(
        deleteAdmin,
      action.body,
    );
    yield put(deleteAdminSuccess(response));
  } catch (error) {
    yield put(deleteAdminFailure(error));
  }
}

export function* deleteAdminSaga() {
  yield takeEvery(
    AdminSettingTypes.DELETE_ADMIN_REQUEST,
    asyncDeleteAdminRequest,
  );
}