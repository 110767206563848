import { StoreSettingTypes as Type } from '../../../types/store';

const initialState = {
  storeList: [],
  loading: false,
  error: null,
};

const StoreListReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case Type.GET_STORE_LIST_REQUEST:
      return { ...state, loading: true, error: null, storeList: [] };
    case Type.GET_STORE_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        storeList: action.payload,
        loading: false,
      };
    case Type.GET_STORE_LIST_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };
    default:
      return state;
  }
};

export default StoreListReducer;