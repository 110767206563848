import axios from "axios";
import { paramsHelper } from "../utils/jwtHelper";

export const getReferralList = (data: any) => {
  try {
    let paramsData = {
      ...data
    }
    let params = paramsHelper(paramsData)
    return axios
      .post(`/users/get-all-refferd-users` + params, {}, {})
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};
