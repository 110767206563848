import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Page from '../../components/page-title';
import Logo from '../../components/logo';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Container, TextField, Typography } from '@mui/material';
import { getChangePasswordRequest } from '../../redux/actions/user/reset_password';
import { checkTokenExpiryRequest } from '../../redux/actions/user/check_expiry';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../components/loader';
import './index.css';
import { displayToast } from '../../helpers/notification';

const useStyles = makeStyles((theme: any) => ({
  root: {
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: 20,
  },
  rootInput: {
    '& .MuiInput-root.Mui-error .MuiInput-input': {
      borderColor: '#c00975',
      color: theme.palette.text.primary,
    },
  },
}));

const ResetPassword = () => {
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState('');
  const [invalid, setInvalid] = useState({
    newPassword: false,
    confirmPassword: false,
  });
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [update, setUpdate] = useState(false); // Password Updated State

  const classes = useStyles();
  const dispatch = useDispatch();

  const { loading, successfullyChanged } = useSelector((state: any) => state.ResetPasswordReducer,);

  const { error } = useSelector((state: any) => state.CheckTokenExpiryReducer,);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get("token");
  const hash = query.get("hash");

  useEffect(() => {
    const data = {
      hash,
      token
    }
    dispatch(checkTokenExpiryRequest(data))
  }, [])

  useEffect(() => {
    if (error && error.response.data.statusCode === 409) {
      navigate('/')
      displayToast('ERROR', error.response.data.message)
    }else if(error && error.response.data.statusCode === 400){
      navigate('/')
      displayToast('ERROR', 'hash and/or token is invalid')
    }
  }, [error])

  useEffect(() => {
    if (!token && !hash) {
      navigate('/')
    }
  }, [])

  useEffect(() => {
    if (successfullyChanged && successfullyChanged) {
      setUpdate(true)
    }
  }, [successfullyChanged])

  const validate = (values: any) => {
    setInvalid({ newPassword: false, confirmPassword: false });
    setErrorMsg('');
    setButtonDisabled(true);

    if (values.confirmPassword !== values.newPassword) {
      setInvalid({ ...invalid, confirmPassword: true });
      setErrorMsg('Passwords do not match.');
      return false;
    } else if (values.newPassword.length < 8) {
      setInvalid({ ...invalid, newPassword: true });
      setErrorMsg('Password must be minimum 8 characters');
      return false;
    }
    else {
      return true;
    }
  };

  const checkData = (newPassword: any) => {
    // setUpdate(true)
    const data = {
      hash: hash,
      token: token,
      password: newPassword
    }
    dispatch(
      getChangePasswordRequest(data),
    );
  };

  return (
    <>
      {!update ? (
        <Page className="reset-password-bg" title="Reset Password">
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Logo />
            <Container className="reset-password-form" maxWidth="sm">
              <Typography className="reset-password-title" variant="h1">
                Reset Password
              </Typography>

              <Formik
                initialValues={{
                  newPassword: '',
                  confirmPassword: '',
                }}
                validationSchema={Yup.object().shape({
                  newPassword: Yup.string()
                    .max(255)
                    .required('New Password is required'),
                })}
                onSubmit={async (values) => {
                  const { newPassword } = values;

                  if (!validate(values)) {
                    return;
                  }
                  checkData(newPassword);
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  isValid,
                  dirty,
                }) => (
                  <form
                    autoComplete="off"
                    onSubmit={handleSubmit}
                    className="reset-form-class"
                  >
                    <Typography variant="h4" className="reset-error">
                      {errorMsg ? errorMsg : ''}
                    </Typography>
                    <Typography
                      style={{
                        color: invalid.newPassword ? '#dd3030' : '#1e272e',
                        marginTop: 25,
                      }}
                      variant="h2"
                      className="reset-label"
                    >
                      New Password
                    </Typography>
                    <TextField
                      variant="standard"
                      className={classes.rootInput}
                      error={Boolean(
                        (touched.newPassword && errors.newPassword) ||
                        invalid.newPassword,
                      )}
                      fullWidth
                      InputProps={{
                        className: 'reset-inputfield',
                      }}
                      autoComplete="off"
                      InputLabelProps={{
                        style: {
                          fontFamily: 'SF-Pro-Regular',
                          fontSize: '16px',
                          fontWeight: '400',
                          fontStretch: 'normal',
                          fontStyle: 'regular',
                          lineHeight: '24px',
                          letterSpacing: '0.2px',
                          color: '#8E8E93',
                          opacity: 100,
                          marginTop: -16,
                        },
                        shrink: false,
                      }}
                      label={
                        values.newPassword === '' ? 'Enter New Password' : ''
                      }
                      name="newPassword"
                      onBlur={handleBlur}
                      onChange={(event) => {
                        setErrorMsg('');
                        setButtonDisabled(false);
                        handleChange(event);
                        setInvalid({ ...invalid, newPassword: false });
                      }}
                      type="password"
                      value={values.newPassword}
                    />

                    <Typography
                      style={{
                        color: invalid.confirmPassword ? '#dd3030' : '#1e272e',
                        marginTop: 10,
                      }}
                      variant="h2"
                      className="reset-label"
                    >
                      Confirm New Password
                    </Typography>
                    <TextField
                      variant="standard"
                      className={classes.rootInput}
                      error={Boolean(
                        (touched.confirmPassword && errors.confirmPassword) ||
                        invalid.confirmPassword,
                      )}
                      fullWidth
                      InputProps={{
                        className: 'reset-inputfield',
                      }}
                      autoComplete="off"
                      InputLabelProps={{
                        style: {
                          fontFamily: 'SF-Pro-Regular',
                          fontSize: '16px',
                          fontWeight: '400',
                          fontStretch: 'normal',
                          fontStyle: 'regular',
                          lineHeight: '24px',
                          letterSpacing: '0.2px',
                          color: '#8E8E93',
                          opacity: 100,
                          marginTop: -16,
                        },
                        shrink: false,
                      }}
                      label={
                        values.confirmPassword === ''
                          ? 'Enter New Password'
                          : ''
                      }
                      name="confirmPassword"
                      onBlur={handleBlur}
                      onChange={(event) => {
                        setErrorMsg('');
                        setButtonDisabled(false);
                        handleChange(event);
                        setInvalid({ ...invalid, confirmPassword: false });
                      }}
                      type="password"
                      value={values.confirmPassword}
                    />
                    <Box>
                      {loading ?
                        <div className="loader-del">
                          <Loader />
                        </div>

                        :
                        <Button
                          disabled={!(isValid && dirty && !buttonDisabled)}
                          fullWidth
                          size="large"
                          className={
                            !(isValid && dirty && !buttonDisabled)
                              ? 'reset-button'
                              : 'reset-button-show'
                          }
                          type="submit"
                          variant="contained"
                        >
                          Reset
                        </Button>
                      }
                    </Box>
                  </form>
                )}
              </Formik>
            </Container>
          </Box>
        </Page>
      ) : (
        // if new and confirm passwords match + password is > 8, then...

        <Page className="password-updated-bg" title="Password Updated">
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Logo />
            <Container className="password-updated-form" maxWidth="sm">
              <div className="password-updated-head-icon">
                <div className="password-updated-done-icon-bg">
                  <svg width="22" height="22" className='password-updated-done-icon' viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.60567 21.5C8.20078 21.5 7.83092 21.409 7.49611 21.2271C7.16129 21.0452 6.84594 20.7565 6.55005 20.361L1.08398 13.5746C0.881535 13.3373 0.733593 13.0881 0.640156 12.8271C0.546719 12.5661 0.5 12.3011 0.5 12.0322C0.5 11.4943 0.686874 11.0316 1.06062 10.6441C1.43437 10.2565 1.88598 10.0627 2.41546 10.0627C2.74249 10.0627 3.04616 10.1299 3.32647 10.2644C3.60678 10.3989 3.87931 10.648 4.14405 11.0119L8.51223 16.683L17.7859 1.59153C18.2375 0.863842 18.8059 0.5 19.4911 0.5C20.005 0.5 20.4683 0.666102 20.881 0.998305C21.2937 1.33051 21.5 1.77345 21.5 2.32712C21.5 2.59604 21.4416 2.86102 21.3248 3.12203C21.208 3.38305 21.0717 3.6322 20.916 3.86949L10.5679 20.361C10.3187 20.7407 10.0306 21.0254 9.70356 21.2153C9.37653 21.4051 9.01057 21.5 8.60567 21.5Z" fill="#43B02A" />
                  </svg>

                </div>
              </div>
              <Typography className="password-updated-title" variant="h1">
                Password Updated
              </Typography>

              <Typography className="password-updated-text">
                Your password has been updated. Login with your new password to
                complete the process.
              </Typography>

              <Box className="reset-box-button">
                <Link to="/login" className="reset-back-to-login-link">
                  <Button
                    size="large"
                    className="Reset-back-to-login"
                    type="submit"
                    variant="contained"
                  >
                    Back to Login
                  </Button>
                </Link>
              </Box>
            </Container>
          </Box>
        </Page>
      )}
    </>
  );
};

export default ResetPassword;
