import axios from 'axios';
import { paramsHelper } from '../utils/jwtHelper';

export const getAdminList = (data: any) => {
  try {
    let paramsData = {
      ...data,
    };
    let params = paramsHelper(paramsData);
    return axios
      .post(`/users/get-all-admin-users` + params, {}, {})
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const addNewAdmin = (data: any) => {
  try {
    return axios
      .post(`/auth/add-admin-users`, data)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const updateAdmin = (data: any) => {
  try {
    return axios
      .post(`/auth/update-admin-users`, data)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const deleteAdmin = (data: any) => {
  try {
    return axios
      .post(`/auth/remove-admin-users${data}`, {})
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const changePassword = (data: any) => {
  try {
    return axios
      .post(`/auth/update-admin-password`, data)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};
