import { takeEvery, put, call } from 'redux-saga/effects';
import { CustomerReportsTypes } from '../../../types/customerreport';
import {
  reOpenTicketRequestSuccess,
  reOpenTicketRequestFailure,
} from '../../../actions/customerreport/reopenticket'
import { reOpenCustomerTicket } from '../../../../services/customerreport';

function* asyncCustomerReOpenTicketRequest(action: any): any {
  try {
    const response = yield call(
    reOpenCustomerTicket,
    action.body
    );
    yield put(reOpenTicketRequestSuccess(response));
  } catch (error) {
    yield put(reOpenTicketRequestFailure(error));
  }
}

export function* reOpenCustomerReportTicketSaga() {
  yield takeEvery(
    CustomerReportsTypes.REOPEN_CUSTOMER_REPORT_TICKET_REQUEST,
    asyncCustomerReOpenTicketRequest,
  );
}
