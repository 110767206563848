import { displayToast } from '../../../../helpers/notification';
import { CustomerReportsTypes as Type } from '../../../types/customerreport';

export function getCustomerReportListRequest(body: any) {
  return {
    type: Type.GET_CUSTOMER_REPORT_LIST_REQUEST,
    body: body,
  };
}

export function getCustomerReportListRequestSuccess(data: any) {
  return {
    type: Type.GET_CUSTOMER_REPORT_LIST_REQUEST_SUCCESS,
    payload: data,
  };
}

export function getCustomerReportListRequestFailure(error: any) {
  if (Array.isArray(error.response.data.message))
    displayToast('ERROR', error.response.data.message[0]);
  else displayToast('ERROR', error.response.data.message);
  return {
    type: Type.GET_CUSTOMER_REPORT_LIST_REQUEST_FAILURE,
    error: error,
  };
}
