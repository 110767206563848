import { takeEvery, put, call } from 'redux-saga/effects';
import { FeaturedContentTypes } from '../../../types/featuredcontent';
import {
  getFeaturedContentListRequestSuccess,
  getFeaturedContentListRequestFailure,
} from '../../../actions/featuredcontent/list'
import { getContentList } from '../../../../services/featuredcontent';

function* asyncGetFeaturedContentListRequest(action: any): any {
  try {
    const response = yield call(
      getContentList,
    );
    yield put(getFeaturedContentListRequestSuccess(response));
  } catch (error) {
    yield put(getFeaturedContentListRequestFailure(error));
  }
}

export function* getFeaturedContentListSaga() {
  yield takeEvery(
    FeaturedContentTypes.GET_FEATURED_CONTENT_LIST_REQUEST,
    asyncGetFeaturedContentListRequest,
  );
}
