import { FaqTypes as Type } from '../../../types/faq';

const initialState = {
  message: null,
  loading: false,
  error: null,

};

const DeleteFaqReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case Type.DELETE_FAQ_REQUEST:
      return { ...state, message: null, loading: true, error: null};

    case Type.DELETE_FAQ_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message 
      };
    case Type.DELETE_FAQ_REQUEST_FAILURE:
      return { ...state, message: null,  error: action.error, loading: false };
   case Type.RESET_DELETE_FAQ_STATE:
        return { ...initialState };
     default:
        return state;
  }
};

export default DeleteFaqReducer;