import React, { useEffect, useState } from 'react';
import './index.css';
import Page from '../../components/page-title';
import Logo from '../../components/logo';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, Container, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Link } from 'react-router-dom';
import { getForgotPasswordRequest } from '../../redux/actions/user/forgot_password';
import { useDispatch, useSelector } from 'react-redux';
import { ResetStateChangePassword } from '../../redux/actions/user/reset_password';
import Loader from '../../components/loader';

const useStyles = makeStyles((theme: any) => ({
  root: {
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: 20,
  },
  rootInput: {
    '& .MuiInput-root.Mui-error .MuiInput-input': {
      borderColor: '#c00975',
      color: theme.palette.text.primary,
    },
  },
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { error, forgotData, loading } = useSelector(
    (state: any) => state.ForgotPasswordReducer,
  );
  const [errorMsg, setErrorMsg] = useState('');
  const [invalid, setInvalid] = useState({ email: false });
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [action, setAction] = useState(false);
  const validateEmail = (email: string) => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  };

  const validate = (values: any) => {
    setInvalid({ email: false });
    setErrorMsg('');
    setButtonDisabled(true);
    if (!validateEmail(values.email)) {
      setInvalid({ ...invalid, email: true });
      setErrorMsg('Please enter a valid email address');
      return false;
    } else {
      return true;
    }
  };

  const checkData = (email: any) => {
    dispatch(getForgotPasswordRequest({ email }));
  };

  useEffect(() => {
    if (error) {
      setAction(false);
      setErrorMsg('Email does not exist.');
      setInvalid({ ...invalid, email: true });
    }
    if (forgotData) {
      setAction(true);
    }
  }, [error, forgotData]);

  return (
    <>
      {forgotData == null ? (
        // Default forgot Password UI + if email not exists
        <Page className="auth-bg" title="Forgot Password">
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Logo />
            <Container className="forgot-form" maxWidth="sm">
              <div className="forgot-title-head">
                <Link to="/login" className="forgot-back-icon-link">
                  {' '}
                  <ArrowBackIosNewIcon className="forgot-back-arrow-icon" />
                </Link>
                <Typography className="forgot-password-title" variant="h1">
                  Forgot Password
                </Typography>
              </div>
              <Typography className="forgot-password-text">
                Enter your registered email below to receive reset password
                instructions.
              </Typography>

              <Formik
                initialValues={{
                  email: '',
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string().max(255).required('Email is required'),
                })}
                onSubmit={async (values) => {
                  const { email } = values;
                  if (!validate(values)) {
                    return;
                  }
                  checkData(email);
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  isValid,
                  dirty,
                }) => (
                  <form
                    autoComplete="off"
                    onSubmit={handleSubmit}
                    className="auth-form-class"
                  >
                    <Typography variant="h4" className="auth-error">
                      {errorMsg ? errorMsg : ''}
                    </Typography>
                    <Typography
                      style={{
                        color: invalid.email ? '#dd3030' : '#1e272e',
                        marginTop: 25,
                      }}
                      variant="h2"
                      className="auth-label"
                    >
                      Email
                    </Typography>
                    <TextField
                      variant="standard"
                      className={classes.rootInput}
                      error={Boolean(
                        (touched.email && errors.email) || invalid.email,
                      )}
                      fullWidth
                      style={{ marginBottom: '30px' }}
                      InputProps={{
                        className: 'auth-inputField forgot-inputField',
                      }}
                      autoComplete="off"
                      InputLabelProps={{
                        style: {
                          fontFamily: 'SF-Pro-Regular',
                          fontSize: '16px',
                          fontWeight: '400',
                          fontStretch: 'normal',
                          fontStyle: 'normal',
                          lineHeight: '24px',
                          letterSpacing: '0.2px',
                          color: '#8E8E93',
                          opacity: 100,
                          marginTop: -16,
                        },
                        shrink: false,
                      }}
                      label={values.email === '' ? 'Enter Email' : ''}
                      name="email"
                      onBlur={handleBlur}
                      onChange={(event) => {
                        setErrorMsg('');
                        setButtonDisabled(false);
                        handleChange(event);
                        setInvalid({ email: false });
                      }}
                      type="text"
                      value={values.email}
                    />
                    <Box>
                      {loading ?
                        <div className="loader-del">
                          <Loader />
                        </div>

                        :
                        <Button
                          disabled={!(isValid && dirty && !buttonDisabled)}
                          fullWidth
                          size="large"
                          className={
                            !(isValid && dirty && !buttonDisabled)
                              ? 'auth-button'
                              : 'auth-button-show'
                          }
                          type="submit"
                          variant="contained"
                        >
                          Send
                        </Button>
                      }
                    </Box>
                  </form>
                )}
              </Formik>
            </Container>
          </Box>
        </Page>
      ) : (
        // if email exists, then verification screen/UI will be visible
        <Page className="verification-email-bg" title="Verification Email Sent">
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Logo />
            <Container className="email-verification-form" maxWidth="sm">
              <div className="email-sent-head-icon">
                <div className="email-sent-done-icon-bg">
                  <svg width="22" height="22" className='email-sent-done-icon' viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.60567 21.5C8.20078 21.5 7.83092 21.409 7.49611 21.2271C7.16129 21.0452 6.84594 20.7565 6.55005 20.361L1.08398 13.5746C0.881535 13.3373 0.733593 13.0881 0.640156 12.8271C0.546719 12.5661 0.5 12.3011 0.5 12.0322C0.5 11.4943 0.686874 11.0316 1.06062 10.6441C1.43437 10.2565 1.88598 10.0627 2.41546 10.0627C2.74249 10.0627 3.04616 10.1299 3.32647 10.2644C3.60678 10.3989 3.87931 10.648 4.14405 11.0119L8.51223 16.683L17.7859 1.59153C18.2375 0.863842 18.8059 0.5 19.4911 0.5C20.005 0.5 20.4683 0.666102 20.881 0.998305C21.2937 1.33051 21.5 1.77345 21.5 2.32712C21.5 2.59604 21.4416 2.86102 21.3248 3.12203C21.208 3.38305 21.0717 3.6322 20.916 3.86949L10.5679 20.361C10.3187 20.7407 10.0306 21.0254 9.70356 21.2153C9.37653 21.4051 9.01057 21.5 8.60567 21.5Z" fill="#43B02A" />
                  </svg>
                </div>
              </div>
              <Typography className="email-sent-title" variant="h1">
                Email Sent
              </Typography>

              <Typography className="email-sent-text">
                Please check your inbox to for password reset instructions.
              </Typography>

              <Box className="email-sent-box-button">
                <Link
                  to="/login"
                  className="email-sent-back-to-login-link"
                  onClick={() => {
                    dispatch(ResetStateChangePassword());
                    setAction(false);
                  }}
                >
                  <Button
                    size="large"
                    className="email-sent-back-to-login"
                    type="submit"
                    variant="contained"
                  >
                    Back to Login
                  </Button>
                </Link>
              </Box>
            </Container>
          </Box>
        </Page>
      )}
    </>
  );
};
export default ForgotPassword;
