import { takeEvery, put, call } from 'redux-saga/effects';
import { StoreSettingTypes } from '../../../types/store';
import {
  getCSVStoreListRequestSuccess,
  getCSVStoreListRequestFailure,
} from '../../../actions/store/csv'
import { getCSVStoreList } from '../../../../services/store';

function* asyncCSVStoreListRequest(action: any): any {
  try {
    const response = yield call(
      getCSVStoreList,
      action.body
    );
    yield put(getCSVStoreListRequestSuccess(response));
  } catch (error) {
    yield put(getCSVStoreListRequestFailure(error));
  }
}

export function* getCSVStoreListSaga() {
  yield takeEvery(
    StoreSettingTypes.GET_CSV_STORE_LIST_REQUEST,
    asyncCSVStoreListRequest,
  );
}
