import { takeEvery, put, call } from 'redux-saga/effects';
import { FaqTypes } from '../../../types/faq';
import {
  getFaqsListRequestSuccess,
  getFaqsListRequestFailure,
} from '../../../actions/faq/list';
import { getFAQList } from '../../../../services/faq';

function* asyncFAQListRequest(action: any): any {
  try {
    const response = yield call(getFAQList, action.body, action.filterData);
    yield put(getFaqsListRequestSuccess(response));
  } catch (error) {
    yield put(getFaqsListRequestFailure(error));
  }
}

export function* getFAQListSaga() {
  yield takeEvery(
    FaqTypes.GET_FAQ_LIST_REQUEST,
    asyncFAQListRequest,
  );
}
