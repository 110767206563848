import { Link } from 'react-router-dom';
import './index.css';
const NotFound = () => {
  return (
    <>
      <div className="main-bg">
        <img src={'../../assests/images/logo_white.png'} alt="" />
        <h1>Page Not Found</h1>
        <p>
          We are sorry, the page you requested cound not be found. Please go
          back to Home page.
        </p>
        <Link to="/">Go Home</Link>
      </div>
    </>
  );
};

export default NotFound;
