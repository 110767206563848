import { takeEvery, put, call } from 'redux-saga/effects';
import { AdminSettingTypes } from '../../../types/adminsetting';
import {
  getAdminListRequestSuccess,
  getAdminListRequestFailure,
} from '../../../actions/adminsetting/list'
import { getAdminList } from '../../../../services/admin';

function* asyncAdminListRequest(action: any): any {
  try {
    const response = yield call(
    getAdminList,
    action.body
    );
    yield put(getAdminListRequestSuccess(response));
  } catch (error) {
    yield put(getAdminListRequestFailure(error));
  }
}

export function* getAdminListSaga() {
  yield takeEvery(
    AdminSettingTypes.GET_ADMIN_LIST_REQUEST,
    asyncAdminListRequest,
  );
}
