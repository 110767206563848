import { type } from 'os';
import { MenuTypes as Type } from '../../../types/menu';

const initialState = {
  syncMenu: null,
  syncLoading: false,
  syncError: null,
};

const MenuDataSyncReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case Type.SYNC_MENU_DATA:
      return { ...state, syncLoading: true, syncError: null, syncMenu: null };
    case Type.SYNC_MENU_DATA_SUCCESS:
      return {
        ...state,
        syncMenu: action.payload,
        syncLoading: false,
      };
    case Type.SYNC_MENU_DATA_FAILURE:
      return { ...state, syncError: action.error, syncLoading: false, syncMenu: null };
    case Type.RESET_SYNC_MENU_STATE:
      return { ...initialState };
    default:
      return state;
  }
};

export default MenuDataSyncReducer;
