import { takeEvery, put, call } from 'redux-saga/effects';
import { StoreSettingTypes } from '../../../types/store';
import {
    createMerchantAccountRequestSuccess,
    createMerchantAccountRequestFailure,
} from '../../../actions/store/createmerchantaccount';
import { createStoreStripeId } from '../../../../services/store';

function* asyncCreateMerchantAccountRequest(action: any): any {
  try {
    const response = yield call(createStoreStripeId, action.id);
    yield put(createMerchantAccountRequestSuccess(response));
  } catch (error) {
    yield put(createMerchantAccountRequestFailure(error));
  }
}

export function* storeCreateMerchantAccountSaga() {
  yield takeEvery(
    StoreSettingTypes.CREATE_STORE_MERCHANT_ACCOUNT,
    asyncCreateMerchantAccountRequest,
  );
}
