import { AdminSettingTypes as Type } from '../../../types/adminsetting';
import { displayToast } from '../../../../helpers/notification';

export function deleteAdminRequest(body: any) {
  return {
    type: Type.DELETE_ADMIN_REQUEST,
    body: body,
  };
}

export function deleteAdminSuccess(data: any) {
  return {
    type: Type.DELETE_ADMIN_REQUEST_SUCCESS,
    payload: data,
  };
}

export function deleteAdminFailure(error: any) {
  try {
    if (
      error.response &&
      error.response.data &&
      error.response.data.message &&
      error.response.data.message.length > 0
    ) {
      if (Array.isArray(error.response.data.message))
        displayToast('ERROR', error.response.data.message[0]);
      else displayToast('ERROR', error.response.data.message);
    } else {
      displayToast('ERROR', error.message);
    }
  } catch (e) {
    displayToast('ERROR', error.message);
  }
  return {
    type: Type.DELETE_ADMIN_REQUEST_FAILURE,
    error: error,
  };
}

export function resetDeleteAdminState() {
  return {
    type: Type.RESET_DELETE_ADMIN_STATE,
  };
}
