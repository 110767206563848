import { takeEvery, put, call } from 'redux-saga/effects';
import { MenuTypes } from '../../../types/menu';
import {
  getMenuListRequestSuccess,
  getMenuListRequestFailure,
} from '../../../actions/menu/list'
import { getMenuList } from '../../../../services/menu';

function* asyncGetMenuListRequest(action: any): any {
  try {
    const response = yield call(
        getMenuList,
       action.data
    );
    yield put(getMenuListRequestSuccess(response));
  } catch (error) {
    yield put(getMenuListRequestFailure(error));
  }
}

export function* getMenuListSaga() {
  yield takeEvery(
    MenuTypes.GET_MENU_LIST_REQUEST,
    asyncGetMenuListRequest,
  );
}
