import axios from "axios";
import { AnyObject } from "yup/lib/types";
import { paramsHelper } from "../utils/jwtHelper";



export const getOrdersList = (data: AnyObject) => {
    try {
      let paramsData = {
        ...data
    }
      let params = paramsHelper(paramsData)
      return axios
        .get(`/order` + params)
        .then((response) => response.data)
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      throw error;
    }
};

export const getOrderDetail = (id: any) => {
  try {
    return axios
      .get(`/order/` + id)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};