import { FeaturedContentTypes as Type } from '../../../types/featuredcontent';

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const DeleteFeaturedContentReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case Type.DELETE_FEATURED_CONTENT_REQUEST:
      return { ...state, loading: true, error: null, data: null };
    case Type.DELETE_FEATURED_CONTENT_REQUEST_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case Type.DELETE_FEATURED_CONTENT_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false, data: null };
    case Type.RESET_DELETE_FEATURED_CONTENT_REQUEST:
      return { ...initialState };
    default:
      return state;
  }
};

export default DeleteFeaturedContentReducer;
