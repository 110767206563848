import { takeEvery, put, call } from 'redux-saga/effects';
import { MenuTypes } from '../../../types/menu';
import {
  updateMenuItemRequestSuccess,
  updateMenuItemRequestFailure,
} from '../../../actions/menu/update'
import { updateSingleMenuItem } from '../../../../services/menu';

function* asyncUpdateMenuListRequest(action: any): any {
  try {
    const response = yield call(
      updateSingleMenuItem,
      action.id,
      action.body,
    );
    yield put(updateMenuItemRequestSuccess(response));
  } catch (error) {
    yield put(updateMenuItemRequestFailure(error));
  }
}

export function* updateMenuListSaga() {
  yield takeEvery(
    MenuTypes.EDIT_MENU_ITEM_REQUEST,
    asyncUpdateMenuListRequest,
  );
}
