import { takeEvery, put, call } from 'redux-saga/effects';
import { ReferralTypes } from '../../types/referral';
import { getReferralListRequestSuccess, getReferralListRequestFailure } from '../../actions/referral';
import { getReferralList } from '../../../services/referral';

function* asyncReferralListRequest(action: any): any {
  try {
    const response = yield call(
      getReferralList,
      action.body,
    );
    yield put(getReferralListRequestSuccess(response));
  } catch (error) {
    yield put(getReferralListRequestFailure(error));
  }
}

export function* getReferralListSaga() {
  yield takeEvery(
    ReferralTypes.GET_REFERRAL_LIST_REQUEST,
    asyncReferralListRequest,
  );
}
