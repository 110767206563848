import { displayToast } from "../../../../helpers/notification";
import { StoreSettingTypes as Type } from "../../../types/store";


export function createMerchantAccountRequest(id: any) {
  return {
    type: Type.CREATE_STORE_MERCHANT_ACCOUNT,
    id
  };
}

export function createMerchantAccountRequestSuccess(data: any) {
  return {
    type: Type.CREATE_STORE_MERCHANT_ACCOUNT_SUCCESS,
    payload: data,
  };
}

export function createMerchantAccountRequestFailure(error: any) {
    try {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message &&
        error.response.data.message.length > 0
      ) {
        if (Array.isArray(error.response.data.message))
          displayToast('ERROR', error.response.data.message[0]);
        else displayToast('ERROR', error.response.data.message);
      } else {
        displayToast('ERROR', error.message);
      }
    } catch (e) {
      displayToast('ERROR', error.message);
    }
    return {
      type: Type.CREATE_STORE_MERCHANT_ACCOUNT_FAILURE,
      error: error,
    };
  }