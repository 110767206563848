import { StoreSettingTypes as Type } from '../../types/store';

const initialState = {
  store: null,
  loading: false,
  error: null,
};

const StoreDetailReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case Type.STORE_DETAIL_REQUEST:
      return { ...state, loading: true, error: null, store: null };
    case Type.STORE_DETAIL_REQUEST_SUCCESS:
      return {
        ...state,
        store: action.payload,
        loading: false,
      };
    case Type.STORE_DETAIL_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false, store: null };
    default:
      return state;
  }
};

export default StoreDetailReducer;