export enum CustomerReportsTypes {
    GET_CUSTOMER_REPORT_LIST_REQUEST = 'GET_CUSTOMER_REPORT_LIST_REQUEST',
    GET_CUSTOMER_REPORT_LIST_REQUEST_SUCCESS = 'GET_CUSTOMER_REPORT_LIST_REQUEST_SUCCESS',
    GET_CUSTOMER_REPORT_LIST_REQUEST_FAILURE = 'GET_CUSTOMER_REPORT_LIST_REQUEST_FAILURE',

    REOPEN_CUSTOMER_REPORT_TICKET_REQUEST = 'REOPEN_CUSTOMER_REPORT_TICKET_REQUEST',
    REOPEN_CUSTOMER_REPORT_TICKET_REQUEST_SUCCESS = 'REOPEN_CUSTOMER_REPORT_TICKET_REQUEST_SUCCESS',
    REOPEN_CUSTOMER_REPORT_TICKET_REQUEST_FAILURE = 'REOPEN_CUSTOMER_REPORT_TICKET_REQUEST_FAILURE',
    RESET_REOPEN_TICKET_STATE = 'RESET_REOPEN_TICKET_STATE',

    CLOSED_CUSTOMER_REPORT_TICKET_REQUEST = 'CLOSED_CUSTOMER_REPORT_TICKET_REQUEST',
    CLOSED_CUSTOMER_REPORT_TICKET_REQUEST_SUCCESS = 'CLOSED_CUSTOMER_REPORT_TICKET_REQUEST_SUCCESS',
    CLOSED_CUSTOMER_REPORT_TICKET_REQUEST_FAILURE = 'CLOSED_CUSTOMER_REPORT_TICKET_REQUEST_FAILURE',
    RESET_CLOSED_TICKET_STATE = 'RESET_CLOSED_TICKET_STATE',

}