import { UserTypes as Type } from "../../../types/user";

export function getChangePasswordRequest(body: any) {
  return {
    type: Type.GET_CHANGE_PASSWORD_REQUEST,
    body: body,
  };
}

export function getChangePasswordRequestSuccess(data: any) {
  return {
    type: Type.GET_CHANGE_PASSWORD_REQUEST_SUCCESS,
    payload: data,
  };
}

export function getChangePasswordRequestFailure(error: any) {
  return {
    type: Type.GET_CHANGE_PASSWORD_REQUEST_FAILURE,
    error: error,
  };
}

export function ResetStateChangePassword() {
  return {
    type: Type.RESET_CHANGE_PASSWORD_STATE,
  };
}
