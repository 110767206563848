import { takeEvery, put, call } from 'redux-saga/effects';
import { UserTypes } from '../../../types/user';
import {
  getForgotPassworrRequestFailure,
  getForgotPassworrRequestSuccess,
} from '../../../actions/user/forgot_password'
import { forgotPassword } from '../../../../services/user';

function* asyncForgotPasswordRequest(action: any): any {
  try {
    const response = yield call(
      forgotPassword,
      action.body,
    );
    yield put(getForgotPassworrRequestSuccess(response));
  } catch (error) {
    yield put(getForgotPassworrRequestFailure(error));
  }
}

export function* forgotPasswordSaga() {
  yield takeEvery(
    UserTypes.GET_FORGOT_PASSWORD_REQUEST,
    asyncForgotPasswordRequest,
  );
}
