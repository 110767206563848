import axios from 'axios';
import { paramsHelper } from '../utils/jwtHelper';

export const addFaq = (body: any) => {
  try {
    return axios
      .post(`/faq/question`, body)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const updateFaq = (data: any, id: any) => {
  try {
    return axios
      .patch(`/faq/question/${id}`, data)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const topicsFaq = () => {
  try {
    return axios
      .get(`/faq/topics`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const getFAQList = (data: any, filterData: any) => {
  try {
    let paramsData = {
      ...data,
    };
    let params = paramsHelper(paramsData);
    return axios
      .get(`/faq/admin` + params)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const DeleteFaq = (id: any) => {
  try {
    return axios
      .delete(`/faq/question/${id}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};
