import { takeEvery, put, call } from 'redux-saga/effects';
import { OrderManagementTypes } from '../../../types/order';
import { getOrdersListRequestSuccess, getOrdersListRequestFailure } from '../../../actions/order/list';
import { getOrdersList } from '../../../../services/order';

function* asyncOrdersListRequest(action: any): any {
  try {
    const response = yield call(
        getOrdersList,
      action.body
    );
    yield put(getOrdersListRequestSuccess(response));
  } catch (error) {
    yield put(getOrdersListRequestFailure(error));
  }
}

export function* getOrdersListSaga() {
  yield takeEvery(
    OrderManagementTypes.GET_ORDERS_LIST_REQUEST,
    asyncOrdersListRequest,
  );
}
