import { takeEvery, put, call } from 'redux-saga/effects';
import { CustomerReportsTypes } from '../../../types/customerreport';
import {
  closeTicketRequestSuccess,
  closeTicketRequestFailure,
} from '../../../actions/customerreport/closeticket'
import { closeCustomerTicket } from '../../../../services/customerreport';

function* asyncCustomerCloseTicketRequest(action: any): any {
  try {
    const response = yield call(
      closeCustomerTicket,
    action.body
    );
    yield put(closeTicketRequestSuccess(response));
  } catch (error) {
    yield put(closeTicketRequestFailure(error));
  }
}

export function* closeCustomerReportTicketSaga() {
  yield takeEvery(
    CustomerReportsTypes.CLOSED_CUSTOMER_REPORT_TICKET_REQUEST,
    asyncCustomerCloseTicketRequest,
  );
}
