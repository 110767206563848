import { StoreSettingTypes as Type } from '../../../types/store';

const initialState = {
  storeCSVList: [],
  loadingCSV: false,
  errorCSV: null,
};

const StoreCSVListReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case Type.GET_CSV_STORE_LIST_REQUEST:
      return { ...state, loadingCSV: true, errorCSV: null, storeCSVList: [] };
    case Type.GET_CSV_STORE_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        storeCSVList: action.payload,
        loadingCSV: false,
      };
    case Type.GET_CSV_STORE_LIST_REQUEST_FAILURE:
      return { ...state, errorCSV: action.errorCSV, loadingCSV: false };
    default:
      return state;
  }
};

export default StoreCSVListReducer;