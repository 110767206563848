import axios from "axios";

export const getUserLogin = (body: any) => {
    try {
      return axios
        .post(`/auth/login/admin`, body)
        .then((response) => response.data)
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      throw error;
    }
};


export const forgotPassword = (body: any) => {
     
  try {
    return axios
      .post(`/auth/forget-password`, body)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const changePassword = (body: any) => {
try {
 return axios
   .post(`/auth/reset-password`, body)
   .then((response) => response.data)
   .catch((error) => {
     throw error;
   });
} catch (error) {
 throw error;
}
};


export const checkHashToken = (body: any) =>{
  try {
    return axios
      .post(`/auth/verify-hash-token`, body)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
   } catch (error) {
    throw error;
   }
}