import { StoreSettingTypes as Type } from '../../../types/store';

const initialState = {
  storeSync: null,
  syncLoading: false,
  syncError: null,
};

const StoreDataSyncReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case Type.SYNC_STORE_DATA:
      return { ...state, syncLoading: true, syncError: null, storeSync: null };
    case Type.SYNC_STORE_DATA_SUCCESS:
      return {
        ...state,
        storeSync: action.payload,
        syncLoading: false,
      };
    case Type.SYNC_STORE_DATA_FAILURE:
      return { ...state, syncError: action.error, syncLoading: false };
    case Type.RESET_SYNC_STORE_STATE:
      return { ...initialState };
    default:
      return state;
  }
};

export default StoreDataSyncReducer;