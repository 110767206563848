import { ImageTypes as Type } from '../../types/image-upload';

const initialState = {
  name: null,
  loading: false,
  error: null,
};

const uploadImageReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case Type.UPLOAD_IMAGES_REQUEST:
      return { ...state, loading: true, error: null, name: null };
    case Type.UPLOAD_IMAGES_REQUEST_SUCCESS:
      return {
        ...state,
        name: action.payload,
        loading: false,
      };
    case Type.UPLOAD_IMAGES_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false, name: null };
    case Type.RESET_UPLOAD_IMAGES_REQUEST:
      return { ...initialState };
    default:
      return state;
  }
};

export default uploadImageReducer;
