import axios from 'axios';

export const uploadsImages = (body: any) => {
  try {
    return axios
      .post(`/file-upload`, body)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};
