import { takeEvery, put, call } from 'redux-saga/effects';
import { FaqTypes } from '../../../types/faq';
import {
  UpdateFaqRequestSuccess,
  UpdateFaqRequestFailure,
} from '../../../actions/faq/update'
import { updateFaq } from '../../../../services/faq';

function* asyncUpdateFaqRequest(action: any): any {
  try {
    const response = yield call(
      updateFaq,
      action.body,
      action.id,
    );
    yield put(UpdateFaqRequestSuccess(response));
  } catch (error) {
    yield put(UpdateFaqRequestFailure(error));
  }
}
export function* updateFaqSaga() {
  yield takeEvery(
    FaqTypes.UPDATE_FAQ_REQUEST,
    asyncUpdateFaqRequest,
  );
}
