import { takeEvery, put, call } from 'redux-saga/effects';
import { FeaturedContentTypes } from '../../../types/featuredcontent';
import {
  deleteFeaturedContentRequestSuccess,
  deleteFeaturedContentRequestFailure,
} from '../../../actions/featuredcontent/delete'
import { deleteFeaturedContent } from '../../../../services/featuredcontent';

function* asyncDeleteFeaturedContentRequest(action: any): any {
  try {
    const response = yield call(
        deleteFeaturedContent,
        action.id
    );
    yield put(deleteFeaturedContentRequestSuccess(response));
  } catch (error) {
    yield put(deleteFeaturedContentRequestFailure(error));
  }
}

export function* deleteFeaturedContentSaga() {
  yield takeEvery(
    FeaturedContentTypes.DELETE_FEATURED_CONTENT_REQUEST,
    asyncDeleteFeaturedContentRequest,
  );
}
