import { UserTypes as Type } from "../../../types/user";

export function getForgotPasswordRequest(body: any) {
  return {
    type: Type.GET_FORGOT_PASSWORD_REQUEST,
    body: body,
  };
}

export function getForgotPassworrRequestSuccess(data: any) {
  return {
    type: Type.GET_FORGOT_PASSWORD_REQUEST_SUCCESS,
    payload: data,
  };
}

export function getForgotPassworrRequestFailure(error: any) {
  return {
    type: Type.GET_FORGOT_PASSWORD_REQUEST_FAILURE,
    error: error,
  };
}
