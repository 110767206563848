import { takeEvery, put, call } from 'redux-saga/effects';
import { StoreSettingTypes } from '../../types/store';
import {
  getStoreDetailsRequestFailure,
  getStoreDetailsRequestSuccess,
} from '../../actions/store';
import { getStoreInfo } from '../../../services/store';

function* asyncStoreDetailRequest(action: any): any {
  try {
    const response = yield call(getStoreInfo, action.id);
    yield put(getStoreDetailsRequestSuccess(response));
  } catch (error) {
    yield put(getStoreDetailsRequestFailure(error));
  }
}

export function* storeDetailSaga() {
  yield takeEvery(
    StoreSettingTypes.STORE_DETAIL_REQUEST,
    asyncStoreDetailRequest,
  );
}
