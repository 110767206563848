import { OrderManagementTypes as Type } from '../../types/order';
const initialState = {
  order: null,
  loading: false,
  error: null,
};

const OrdersDetailReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case Type.GET_ORDERS_DETAIL_REQUEST:
      return { ...state, loading: true, error: null, order: null };
    case Type.GET_ORDERS_DETAIL_REQUEST_SUCCESS:
      return {
        ...state,
        order: action.payload,
        loading: false,
      };
    case Type.GET_ORDERS_DETAIL_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false, order: null };
    default:
      return state;
  }
};

export default OrdersDetailReducer;