import { takeEvery, put, call } from 'redux-saga/effects';
import { FaqTypes } from '../../../types/faq';
import {
  DeleteFaqRequestSuccess,
  DeleteFaqRequestFailure,
} from '../../../actions/faq/delete';
import { DeleteFaq } from '../../../../services/faq';

function* asyncDeleteFaqRequest(action: any): any {
  try {
    const response = yield call(DeleteFaq, action.id);
    yield put(DeleteFaqRequestSuccess(response));
  } catch (error) {
    yield put(DeleteFaqRequestFailure(error));
  }
}

export function* deleteFaqSaga() {
  yield takeEvery(FaqTypes.DELETE_FAQ_REQUEST, asyncDeleteFaqRequest);
}
