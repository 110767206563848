import { FaqTypes as Type } from '../../../types/faq';

const initialState = {
  topics: [],
  loading: false,
  error: null,
};

const TopicsFaqReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case Type.TOPICS_FAQ_REQUEST:
      return { ...state, loading: true, error: null, topics: []};

    case Type.TOPICS_FAQ_REQUEST_SUCCESS:
      return {
        ...state,
        topics: action.payload,
        loading: false,
      };
    case Type.TOPICS_FAQ_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };
      default:
        return state;
  }
};

export default TopicsFaqReducer;