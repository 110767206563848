import { OrderManagementTypes as Type } from '../../../types/order';
const initialState = {
  ordersList: [],
  loading: false,
  error: null,
};

const OrdersListReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case Type.GET_ORDERS_LIST_REQUEST:
      return { ...state, loading: true, error: null, ordersList: [] };
    case Type.GET_ORDERS_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        ordersList: action.payload,
        loading: false,
      };
    case Type.GET_ORDERS_LIST_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };
    default:
      return state;
  }
};

export default OrdersListReducer;