import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import theme from './theme';
import { Provider } from 'react-redux';
import { persistor, store } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import axios from 'axios';
import { setLogoutRequest } from './redux/actions/user/login';

const rootElement: any = document.getElementById('root');
const root = createRoot(rootElement);
const { dispatch } = store;

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_API || '';
axios.defaults.headers.common['Authorization'] =
  'Bearer ' + localStorage.getItem('accessToken');
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(
  (request) => {
    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.data.statusCode === 401) {
      window.location.href = '/';
      dispatch(setLogoutRequest());
    }
    return Promise.reject(error);
  },
);

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter basename="">
        <PersistGate persistor={persistor}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </PersistGate>
      </BrowserRouter>
    </React.StrictMode>
  </Provider>,
);

reportWebVitals();
