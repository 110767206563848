import axios from "axios";
import { AnyObject } from "yup/lib/types";
import { paramsHelper } from "../utils/jwtHelper";



export const getStoreList = (data: AnyObject) => {
    try {
      let paramsData = {
        ...data
    }
      let params = paramsHelper(paramsData)
      return axios
        .get(`/store` + params)
        .then((response) => response.data)
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      throw error;
    }
};
export const getCSVStoreList = (data: AnyObject) => {
  try {
    let paramsData = {
      ...data
  }
    let params = paramsHelper(paramsData)
    return axios
      .get(`/store` + params)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};
export const getStoreInfo = (id: any) => {
  try {
    return axios
      .get(`/store/${id}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const updateStore = (id: any, body: AnyObject) => {
  try {
    return axios
      .patch(`/store/${id}`, body)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const createStoreStripeId = (id: any) => {
  try {
    return axios
      .post(`/store/createMerchantAccount?storeId=${id}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
}

export const refreshStoreStripeId = (id: any, accountId: any) => {
  try {
    return axios
      .post(`/store/refreshMerchant?storeId=${id}&accountId=${accountId}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
}


export const syncStoreAllData = () => {
  try {
    return axios
      .get(`/store/sync-stores`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
}
