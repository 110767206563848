import { takeEvery, put, call } from 'redux-saga/effects';
import { StoreSettingTypes } from '../../../types/store';
import {
  editStoreDetailsRequestFailure,
  editStoreDetailsRequestSuccess,
} from '../../../actions/store/edit'
import { updateStore } from '../../../../services/store';

function* asyncStoreEditRequest(action: any): any {
  try {
    const response = yield call(
      updateStore,
      action.id,
      action.body
    );
    yield put(editStoreDetailsRequestSuccess(response));
  } catch (error) {
    yield put(editStoreDetailsRequestFailure(error));
  }
}

export function* storeEditSaga() {
  yield takeEvery(
    StoreSettingTypes.EDIT_STORE_REQUEST,
    asyncStoreEditRequest,
  );
}
