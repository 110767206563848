import { FaqTypes as Type } from '../../../types/faq';

const initialState = {
  updateFaq: null,
  updateLoading: false,
  updateError: null,
};

const UpdateFaqReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case Type.UPDATE_FAQ_REQUEST:
      return {
        ...state, updateLoading: true, updateError: null, updateFaq: null
      };

    case Type.UPDATE_FAQ_REQUEST_SUCCESS:
      return {
        ...state,
        updateFaq: action.payload,
        updateLoading: false,
      };

    case Type.UPDATE_FAQ_REQUEST_FAILURE:
      return {
        ...state, updateError: action.error, updateLoading: false
      };
    case Type.RESET_UPDATE_FAQ_STATE:
      return { ...initialState };
    default:
      return state;

  }
};

export default UpdateFaqReducer;