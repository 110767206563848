import { AdminSettingTypes as Type } from '../../../types/adminsetting';

const initialState = {
  adminList: [],
  loading: false,
  error: null,
};

const AdminListReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case Type.GET_ADMIN_LIST_REQUEST:
      return { ...state, loading: true, error: null, adminList: [] };
    case Type.GET_ADMIN_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        adminList: action.payload,
        loading: false,
      };
    case Type.GET_ADMIN_LIST_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };
    default:
      return state;
  }
};

export default AdminListReducer;