import { takeEvery, put, call } from 'redux-saga/effects';
import { AdminSettingTypes } from '../../../types/adminsetting';
import {
  updateAdminRequestSuccess,
  updateAdminRequestFailure,
} from '../../../actions/adminsetting/update'
import { updateAdmin } from '../../../../services/admin';

function* asyncUpdateAdminRequest(action: any): any {
  try {
    const response = yield call(
        updateAdmin,
      action.body,
    );
    yield put(updateAdminRequestSuccess(response));
  } catch (error) {
    yield put(updateAdminRequestFailure(error));
  }
}

export function* updateAdminSaga() {
  yield takeEvery(
    AdminSettingTypes.UPDATE_ADMIN_REQUEST,
    asyncUpdateAdminRequest,
  );
}