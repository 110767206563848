import { takeEvery, put, call } from 'redux-saga/effects';
import { UserTypes } from '../../../types/user';
import {
  getLoginUserRequestSuccess,
  getLoginUserRequestFailure,
} from '../../../actions/user/login'
import { getUserLogin } from '../../../../services/user';

function* asyncGetLoginUserRequest(action: any): any {
  try {
    const response = yield call(
      getUserLogin,
      action.body,
    );
    yield put(getLoginUserRequestSuccess(response));
  } catch (error) {
    yield put(getLoginUserRequestFailure(error));
  }
}

export function* getLoginUserSaga() {
  yield takeEvery(
    UserTypes.GET_LOGIN_REQUEST,
    asyncGetLoginUserRequest,
  );
}
