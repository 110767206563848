import { takeEvery, put, call } from 'redux-saga/effects';
import { AdminSettingTypes } from '../../../types/adminsetting';
import {
  changeAdminPasswordSuccess,
  changeAdminPasswordFailure,
} from '../../../actions/adminsetting/changepassword'
import { changePassword } from '../../../../services/admin';

function* asyncChangeAdminPasswordRequest(action: any): any {
  try {
    const response = yield call(
      changePassword,
      action.body,
    );
    yield put(changeAdminPasswordSuccess(response));
  } catch (error) {
    yield put(changeAdminPasswordFailure(error));
  }
}

export function* changeAdminPasswordSaga() {
  yield takeEvery(
    AdminSettingTypes.CHANGE_ADMIN_PASSWORD_REQUEST,
    asyncChangeAdminPasswordRequest,
  );
}