import { takeEvery, put, call } from 'redux-saga/effects';
import { AdminSettingTypes } from '../../../types/adminsetting';
import {
  addNewAdminRequestSuccess,
  addNewAdminRequestFailure,
} from '../../../actions/adminsetting/add'
import { addNewAdmin } from '../../../../services/admin';

function* asyncAddNewAdminRequest(action: any): any {
  try {
    const response = yield call(
      addNewAdmin,
      action.body,
    );
    yield put(addNewAdminRequestSuccess(response));
  } catch (error) {
    yield put(addNewAdminRequestFailure(error));
  }
}

export function* addNewAdminSaga() {
  yield takeEvery(
    AdminSettingTypes.ADD_NEW_ADMIN_REQUEST,
    asyncAddNewAdminRequest,
  );
}
