import { takeEvery, put, call } from 'redux-saga/effects';
import { FaqTypes } from '../../../types/faq';
import {
  AddFaqRequestSuccess,
  AddFaqRequestFailure,
} from '../../../actions/faq/add'
import { addFaq } from '../../../../services/faq';

function* asyncAddFaqRequest(action: any): any {
  try {
    const response = yield call(
      addFaq,
      action.payload,
    );
    yield put(AddFaqRequestSuccess(response));
  } catch (error) {
    yield put(AddFaqRequestFailure(error));
  }
}

export function* addFaqSaga() {
  yield takeEvery(
    FaqTypes.ADD_FAQ_REQUEST,
    asyncAddFaqRequest,
  );
}
