import axios from 'axios';
import { paramsHelper } from '../utils/jwtHelper';

export const getCustomerReportList = (data: any) => {
  try {
    let paramsData = {
      ...data,
    };
    let params = paramsHelper(paramsData);
    return axios
      .post(`/faq/supportTickets` + params)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const reOpenCustomerTicket = (data: any) => {
  try {
    return axios
      .post(`/faq/reopenTicket/${data}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const closeCustomerTicket = (data: any) => {
  try {
    return axios
      .post(`/faq/closeSupportTicket/${data}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};