export enum MenuTypes {
    GET_MENU_LIST_REQUEST = 'GET_MENU_LIST_REQUEST',
    GET_MENU_LIST_REQUEST_SUCCESS = 'GET_MENU_LIST_REQUEST_SUCCESS',
    GET_MENU_LIST_REQUEST_FAILURE = 'GET_MENU_LIST_REQUEST_FAILURE',

    GET_CATEGORY_LIST_REQUEST = 'GET_CATEGORY_LIST_REQUEST',
    GET_CATEGORY_LIST_REQUEST_SUCCESS = 'GET_CATEGORY_LIST_REQUEST_SUCCESS',
    GET_CATEGORY_LIST_REQUEST_FAILURE = 'GET_CATEGORY_LIST_REQUEST_FAILURE',

    GET_ITEM_LIST_REQUEST = 'GET_ITEM_LIST_REQUEST',
    GET_ITEM_LIST_REQUEST_SUCCESS = 'GET_ITEM_LIST_REQUEST_SUCCESS',
    GET_ITEM_LIST_REQUEST_FAILURE = 'GET_ITEM_LIST_REQUEST_FAILURE',
    RESET_ITEM_LIST_REQUEST = 'RESET_ITEM_LIST_REQUEST',

    EDIT_MENU_ITEM_REQUEST = "EDIT_MENU_ITEM_REQUEST",
    EDIT_MENU_ITEM_REQUEST_FAILURE = "EDIT_MENU_ITEM_REQUEST_FAILURE",
    EDIT_MENU_ITEM_REQUEST_SUCCESS= "EDIT_MENU_ITEM_REQUEST_SUCCESS",
    RESET_EDIT_MENU_ITEM_STATE = 'RESET_EDIT_MENU_ITEM_STATE',

    SYNC_MENU_DATA = 'SYNC_MENU_DATA',
    SYNC_MENU_DATA_SUCCESS = 'SYNC_MENU_DATA_SUCCESS',
    SYNC_MENU_DATA_FAILURE = 'SYNC_MENU_DATA_FAILURE',
    RESET_SYNC_MENU_STATE = 'RESET_SYNC_MENU_STATE',
}
