import { MenuTypes as Type } from '../../../types/menu';

const initialState = {
  updateItem: [],
  loading: false,
  error: null,
};

const EditItemReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case Type.EDIT_MENU_ITEM_REQUEST:
      return { ...state, loading: true, error: null, updateItem: [] };
    case Type.EDIT_MENU_ITEM_REQUEST_SUCCESS:
      return {
        ...state,
        updateItem: action.payload,
        loading: false,
      };
    case Type.EDIT_MENU_ITEM_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };
      case Type.RESET_EDIT_MENU_ITEM_STATE:
        return { ...initialState };
    default:
      return state;
  }
};

export default EditItemReducer;
