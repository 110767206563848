import { takeEvery, put, call } from 'redux-saga/effects';
import { MenuTypes } from '../../../types/menu';
import {
  getItemListRequestSuccess,
  getItemListRequestFailure,
} from '../../../actions/menu/item-list'
import { getItemsList } from '../../../../services/menu';

function* asyncGetItemListRequest(action: any): any {
  try {
    const response = yield call(
        getItemsList,
        action.id
    );
    yield put(getItemListRequestSuccess(response));
  } catch (error) {
    yield put(getItemListRequestFailure(error));
  }
}

export function* getItemListSaga() {
  yield takeEvery(
    MenuTypes.GET_ITEM_LIST_REQUEST,
    asyncGetItemListRequest,
  );
}
