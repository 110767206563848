import { UserTypes as Type } from '../../../types/user';

const initialState = {
  checktokenExpiry: null,
  loading: false,
  error: null,
};

const CheckTokenExpiryReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case Type.CHECK_TOKEN_EXPIRY_REQUEST:
      return { ...state, loading: true,  checktokenExpiry: null,error: null 
      };

    case Type.CHECK_TOKEN_EXPIRY_REQUEST_SUCCESS:
      return {
        ...state,
        checktokenExpiry: action.payload,
        loading: false,
      };
    case Type.CHECK_TOKEN_EXPIRY_REQUEST_FAILURE:
      return { ...state, error: action.error, checktokenExpiry: null, loading: false 
      };
    default:
      return state;
  }
};

export default CheckTokenExpiryReducer;