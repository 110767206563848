import { FeaturedContentTypes as Type } from '../../../types/featuredcontent';

const initialState = {
  content: null,
  loading: false,
  error: null,
};

const FeaturedContentListReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case Type.GET_FEATURED_CONTENT_LIST_REQUEST:
      return { ...state, loading: true, error: null, content: null };
    case Type.GET_FEATURED_CONTENT_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        content: action.payload,
        loading: false,
      };
    case Type.GET_FEATURED_CONTENT_LIST_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false, content: null };
    default:
      return state;
  }
};

export default FeaturedContentListReducer;
