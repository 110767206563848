import { takeEvery, put, call } from 'redux-saga/effects';
import { CustomerReportsTypes } from '../../../types/customerreport';
import {
  getCustomerReportListRequestSuccess,
  getCustomerReportListRequestFailure,
} from '../../../actions/customerreport/list'
import { getCustomerReportList } from '../../../../services/customerreport';

function* asyncCustomerReportListRequest(action: any): any {
  try {
    const response = yield call(
    getCustomerReportList,
    action.body
    );
    yield put(getCustomerReportListRequestSuccess(response));
  } catch (error) {
    yield put(getCustomerReportListRequestFailure(error));
  }
}

export function* getCustomerReportListSaga() {
  yield takeEvery(
    CustomerReportsTypes.GET_CUSTOMER_REPORT_LIST_REQUEST,
    asyncCustomerReportListRequest,
  );
}
