import { StoreSettingTypes as Type } from '../../../types/store';

const initialState = {
  refreshMerchant: null,
  loading: false,
  error: null,
};

const StoreRefreshMerchantReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case Type.REFRESH_STORE_MERCHANT_ACCOUNT:
      return { ...state, loading: true, error: null, refreshMerchant: null };
    case Type.REFRESH_STORE_MERCHANT_ACCOUNT_SUCCESS:
      return {
        ...state,
        refreshMerchant: action.payload,
        loading: false,
      };
    case Type.REFRESH_STORE_MERCHANT_ACCOUNT_FAILURE:
      return { ...state, error: action.error, loading: false, refreshMerchant: null };
    // case Type.RESET_EDIT_STORE_STATE:
    //   return { ...initialState };
    default:
      return state;
  }
};

export default StoreRefreshMerchantReducer;
