import axios from "axios";

export const getConfigurations = () => {
  const request = axios.CancelToken.source()
    try {
      return axios
        .get(`/config`, {
          cancelToken: request.token
        })
        .then((response) => 
         response.data
        //  request.cancel()
         )
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      throw error;
    } 
};
