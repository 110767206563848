import axios from 'axios';

export const getContentList = () => {
  try {
    return axios
      .get(`/feature`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const addFeaturedContent = (body: any) => {
  try {
    return axios
      .post(`/feature/content`, body)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const updateFeaturedContent = (body: any, id: string) => {
  try {
    return axios
      .patch(`/feature/content/${id}`, body)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const deleteFeaturedContent = (id: string) => {
  try {
    return axios
      .delete(`/feature/content/${id}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};
