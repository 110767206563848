import { UserTypes as Type } from "../../../types/user";

export function checkTokenExpiryRequest(body: any) {
  return {
    type: Type.CHECK_TOKEN_EXPIRY_REQUEST,
    body: body,
  };
}

export function checkTokenExpiryRequestSuccess(data: any) {
  return {
    type: Type.CHECK_TOKEN_EXPIRY_REQUEST_SUCCESS,
    payload: data,
  };
}

export function checkTokenExpiryRequestFailure(error: any) {
  return {
    type: Type.CHECK_TOKEN_EXPIRY_REQUEST_FAILURE,
    error: error,
  };
}