import { takeEvery, put, call } from 'redux-saga/effects';
import { GCmembershipTypes } from '../../types/gc-membership';
import { getGCmembershipListRequestSuccess, getGCmembershipListRequestFailure } from '../../actions/gc-membership';
import { getGCmembershipList } from '../../../services/gc-membership';

function* asyncGCmembershipListRequest(action: any): any {
  try {
    const response = yield call(
        getGCmembershipList,
      action.body,
    );
    yield put(getGCmembershipListRequestSuccess(response));
  } catch (error) {
    yield put(getGCmembershipListRequestFailure(error));
  }
}

export function* getGCmembershipListSaga() {
  yield takeEvery(
    GCmembershipTypes.GET_GCMEMBERSHIP_LIST_REQUEST,
    asyncGCmembershipListRequest,
  );
}
