export enum UserTypes {
    GET_LOGIN_REQUEST = 'GET_LOGIN_REQUEST',
    GET_LOGIN_REQUEST_SUCCESS = 'GET_LOGIN_REQUEST_SUCCESS',
    GET_LOGIN_REQUEST_FAILURE = 'GET_LOGIN_REQUEST_FAILURE',

    GET_FORGOT_PASSWORD_REQUEST = 'GET_FORGOT_PASSWORD_REQUEST',
    GET_FORGOT_PASSWORD_REQUEST_SUCCESS = 'GET_FORGOT_PASSWORD_REQUEST_SUCCESS',
    GET_FORGOT_PASSWORD_REQUEST_FAILURE = 'GET_FORGOT_PASSWORD_REQUEST_FAILURE',

    GET_CHANGE_PASSWORD_REQUEST = 'GET_CHANGE_PASSWORD_REQUEST',
    GET_CHANGE_PASSWORD_REQUEST_SUCCESS = 'GET_CHANGE_PASSWORD_REQUEST_SUCCESS',
    GET_CHANGE_PASSWORD_REQUEST_FAILURE = 'GET_CHANGE_PASSWORD_REQUEST_FAILURE',
    RESET_CHANGE_PASSWORD_STATE = 'RESET_CHANGE_PASSWORD_STATE',

    CHECK_TOKEN_EXPIRY_REQUEST = 'CHECK_TOKEN_EXPIRY_REQUEST',
    CHECK_TOKEN_EXPIRY_REQUEST_SUCCESS = 'CHECK_TOKEN_EXPIRY_REQUEST_SUCCESS',
    CHECK_TOKEN_EXPIRY_REQUEST_FAILURE = 'CHECK_TOKEN_EXPIRY_REQUEST_FAILURE',

    SET_LOGOUT_REQUEST = 'SET_LOGOUT_REQUEST',
}