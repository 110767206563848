import { takeEvery, put, call } from 'redux-saga/effects';
import { UserTypes } from '../../../types/user';
import {
  checkTokenExpiryRequestFailure,
  checkTokenExpiryRequestSuccess,
} from '../../../actions/user/check_expiry'
import { checkHashToken } from '../../../../services/user';

function* asyncCheckTokenExpiry(action: any): any {
  try {
    const response = yield call(
        checkHashToken,
      action.body,
    );
    yield put(checkTokenExpiryRequestSuccess(response));
  } catch (error) {
    yield put(checkTokenExpiryRequestFailure(error));
  }
}

export function* checkTokenExpirySaga() {
  yield takeEvery(
    UserTypes.CHECK_TOKEN_EXPIRY_REQUEST,
    asyncCheckTokenExpiry,
  );
}
