import { takeEvery, put, call } from 'redux-saga/effects';
import { FeaturedContentTypes } from '../../../types/featuredcontent';
import { updateFeaturedContent } from '../../../../services/featuredcontent';
import {
  updateFeaturedContentRequestFailure,
  updateFeaturedContentRequestSuccess,
} from '../../../actions/featuredcontent/update';

function* asyncUpdateFeaturedContentRequest(action: any): any {
  try {
    const response = yield call(updateFeaturedContent, action.body, action.id);
    yield put(updateFeaturedContentRequestSuccess(response));
  } catch (error) {
    yield put(updateFeaturedContentRequestFailure(error));
  }
}

export function* updateFeaturedContentSaga() {
  yield takeEvery(
    FeaturedContentTypes.UPDATE_FEATURED_CONTENT_REQUEST,
    asyncUpdateFeaturedContentRequest,
  );
}
