import { takeEvery, put, call } from 'redux-saga/effects';
import { StoreSettingTypes } from '../../../types/store';
import {
  getStoreListRequestSuccess,
  getStoreListRequestFailure,
} from '../../../actions/store/list'
import { getStoreList } from '../../../../services/store';

function* asyncStoreListRequest(action: any): any {
  try {
    const response = yield call(
      getStoreList,
      action.body
    );
    yield put(getStoreListRequestSuccess(response));
  } catch (error) {
    yield put(getStoreListRequestFailure(error));
  }
}

export function* getStoreListSaga() {
  yield takeEvery(
    StoreSettingTypes.GET_STORE_LIST_REQUEST,
    asyncStoreListRequest,
  );
}
