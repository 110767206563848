export enum FaqTypes {
  ADD_FAQ_REQUEST = 'ADD_FAQ_REQUEST',
  ADD_FAQ_REQUEST_SUCCESS = 'ADD_FAQ_SUCCESS',
  ADD_FAQ_REQUEST_FAILURE = 'ADD_FAQ_FAILURE',

  UPDATE_FAQ_REQUEST = ' UPDATE_FAQ_REQUEST',
  UPDATE_FAQ_REQUEST_SUCCESS = ' UPDATE_FAQ_REQUEST_SUCCESS',
  UPDATE_FAQ_REQUEST_FAILURE = ' UPDATE_FAQ_REQUEST_FAILURE',
  RESET_UPDATE_FAQ_STATE = 'RESET_UPDATE_FAQ_STATE',

  TOPICS_FAQ_REQUEST = ' TOPICS_FAQ_REQUEST',
  TOPICS_FAQ_REQUEST_SUCCESS = ' TOPICS_FAQ_REQUEST_SUCCESS',
  TOPICS_FAQ_REQUEST_FAILURE = ' TOPICS_FAQ_REQUEST_FAILURE',

  GET_FAQ_LIST_REQUEST = 'GET_FAQ_LIST_REQUEST',
  GET_FAQ_LIST_REQUEST_SUCCESS = 'GET_FAQ_LIST_REQUEST_SUCCESS',
  GET_FAQ_LIST_REQUEST_FAILURE = 'GET_FAQ_LIST_REQUEST_FAILURE',

  DELETE_FAQ_REQUEST = 'DELETE_FAQ_REQUEST',
  DELETE_FAQ_REQUEST_SUCCESS = 'DELETE_FAQ_REQUEST_SUCCESS',
  DELETE_FAQ_REQUEST_FAILURE = 'DELETE_FAQ_REQUEST_FAILURE',

  RESET_ADD_FAQ_STATE = 'RESET_ADD_FAQ_STATE',
  RESET_DELETE_FAQ_STATE = 'RESET_DELETE_FAQ_STATE',
}
