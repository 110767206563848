import { Route, Routes } from "react-router-dom";
import Login from "../pages/login";
import NotFound from "../pages/not-found";
import ForgotPassword from "../pages/forgot_password";
import ResetPassword from "../pages/reset_password";

const PublicRoutes = () => {
    return (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password"  element={<ResetPassword />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      );
}

export default PublicRoutes;