import { takeEvery, put, call } from 'redux-saga/effects';
import { UserTypes } from '../../../types/user';
import {
  getChangePasswordRequestFailure,
  getChangePasswordRequestSuccess,
} from '../../../actions/user/reset_password'
import { changePassword } from '../../../../services/user';

function* asyncResetPasswordRequest(action: any): any {
  try {
    const response = yield call(
      changePassword,
      action.body,
    );
    yield put(getChangePasswordRequestSuccess(response));
  } catch (error) {
    yield put(getChangePasswordRequestFailure(error));
  }
}

export function* changePasswordSaga() {
  yield takeEvery(
    UserTypes.GET_CHANGE_PASSWORD_REQUEST,
    asyncResetPasswordRequest,
  );
}
