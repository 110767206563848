import { takeEvery, put, call } from 'redux-saga/effects';
import { ConfigurationTypes } from '../../types/configuratios';
import {
  getConfigurationsRequestFailure,
  getConfigurationsRequestSuccess,
} from '../../actions/configuration';
import { getConfigurations } from '../../../services/config';

function* asyncGetConfigurationsRequest(action: any): any {
  try {
    const response = yield call(getConfigurations);
    yield put(getConfigurationsRequestSuccess(response));
  } catch (error) {
    yield put(getConfigurationsRequestFailure(error));
  }
}

export function* getConfigurationsSaga() {
  yield takeEvery(
    ConfigurationTypes.GET_CONFIGURATIONS_REQUEST,
    asyncGetConfigurationsRequest,
  );
}
