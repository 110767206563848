import { takeEvery, put, call } from 'redux-saga/effects';
import { StoreSettingTypes } from '../../../types/store';
import {
    syncStoreDataSuccess,
    syncStoreDataFailure,
} from '../../../actions/store/syncStoreData';
import { syncStoreAllData } from '../../../../services/store';

function* asyncStoreDataSyncRequest(action: any): any {
  try {
    const response = yield call(syncStoreAllData);
    yield put(syncStoreDataSuccess(response));
  } catch (error) {
    yield put(syncStoreDataFailure(error));
  }
}

export function* storeSyncDataSaga() {
  yield takeEvery(
    StoreSettingTypes.SYNC_STORE_DATA,
    asyncStoreDataSyncRequest,
  );
}
