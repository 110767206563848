import { createTheme, colors } from '@mui/material';


const theme = createTheme({
  palette: {
    background: {
      default: colors.common.white,
      paper: colors.common.white,
    },
    primary: {
      main: '#000',
      contrastText: '#1e272e',
      dark: 'linear-gradient(135deg, #fc712b 6%, #c40000 64%, #b01212 88%)',
      light: '#f1f6f7',
    },
    text: {
      primary: '#000',
      secondary: '#1e272e',
    },
  },
  typography: {
    fontFamily: 'SF-Pro-Regular',
    h1: {
      fontFamily: 'SF-Pro-Bold',
      fontSize: '48px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.17,
      letterSpacing: '2.06px',
      color: '#1e272e'
    },
    h2: {
      fontFamily: "SF-Pro-SemiBold",
      fontSize: "28px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.43,
      letterSpacing: "normal",
      color: "#1e272e"
    },
    h3: {
      fontFamily: "SF-Pro-Medium",
      fontSize: "18px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "0.23px",
      color: "#1e272e"
    },
    h4: {
      fontFamily: 'SF-Pro-Regular',
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.5,
      letterSpacing: "0.23px",
      color: "#1e272e"

    },
    subtitle1: {
      fontFamily: "SF-Pro-Bold",
      fontSize: "32px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "0.2px",
      color: "#1e272e"
    },
    subtitle2: {
      fontFamily: "SF-Pro-SemiBold",
      fontSize: "28px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.43,
      letterSpacing: "normal",
      color: "#1e272e"
    },
    button: {
      fontFamily: "SF-Pro-Bold",
      fontSize: "18px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "0.23px",
      textAlign: "center",
      color: "#ffffff"

    }
  },

});

export default theme;
