import { CustomerReportsTypes as Type } from '../../../types/customerreport';

const initialState = {
  closeTicket: null,
  loading: false,
  error: null,
};

const CloseCustomerReporTicketReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case Type.CLOSED_CUSTOMER_REPORT_TICKET_REQUEST:
      return { ...state, loading: true, error: null, closeTicket: null };
    case Type.CLOSED_CUSTOMER_REPORT_TICKET_REQUEST_SUCCESS:
      return {
        ...state,
        closeTicket: action.payload,
        loading: false,
      };
    case Type.CLOSED_CUSTOMER_REPORT_TICKET_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };
    case Type.RESET_CLOSED_TICKET_STATE:
        return { ...initialState };
    default:
      return state;
  }
};

export default CloseCustomerReporTicketReducer;