

export function paramsHelper(data: any) {
    let params = "?";
    for (const key in data) {
        if (data[key] || data[key] === 0) {
            if (Array.isArray(data[key])) {
                params += data[key].reduce((f: any,t: any) => f+ key + "[]" + "=" + t + "&" ,'');
            } else {
                const value = key === "page" ? data[key] + 1 : data[key];
                    params += key  + "=" + value + "&";
            }
        }
    }
    return params;

}