import axios from 'axios';
import { paramsHelper } from '../utils/jwtHelper';


export const getMenuList = (data: any) => {
  try {
    let paramsData = {
      ...data,
    };
    let params = paramsHelper(paramsData);
    return axios.get(`/menu`+ params)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const getCategoryList = () => {
  try {
    return axios
      .get(`/menu/categories`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

export const getItemsList = (id: number) => {
    try {
      return axios
        .get(`/menu/items/${id}`)
        .then((response) => response.data)
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      throw error;
    }
};

export const updateSingleMenuItem = (id: string,  body: any) => {
  try {
    return axios
      .patch(`/menu/item/${id}` , body)
      .then((response) =>  response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};


export const syncMenuAllData = () => {
  try {
    return axios
      .post(`/menu/revel_sync`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
}
