import { takeEvery, put, call } from 'redux-saga/effects';
import { FaqTypes } from '../../../types/faq';
import {
  TopicsFaqRequestSuccess,
  TopicsFaqRequestFailure,
} from '../../../actions/faq/topics'
import { topicsFaq } from '../../../../services/faq';

function*asyncTopicsFaqRequest(action: any): any {
  try {
    const response = yield call(
        topicsFaq
    );
    yield put(TopicsFaqRequestSuccess(response));
  } catch (error) {
    yield put(TopicsFaqRequestFailure(error));
  }
}

export function* topicsFaqSaga() {
  yield takeEvery(
    FaqTypes.TOPICS_FAQ_REQUEST,
    asyncTopicsFaqRequest,
  );
}
