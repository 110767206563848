import { displayToast } from '../../../../helpers/notification';
import { FaqTypes as Type } from '../../../types/faq';

export function UpdateFaqRequest(body: any, id: any) {
  return {
    type: Type.UPDATE_FAQ_REQUEST,
    body: body,
    id: id,
  };
}

export function UpdateFaqRequestSuccess(data: any) {
  return {
    type: Type.UPDATE_FAQ_REQUEST_SUCCESS,
    payload: data,
  };
}

export function UpdateFaqRequestFailure(error: any) {
  try {
    if (
      error.response &&
      error.response.data &&
      error.response.data.message &&
      error.response.data.message.length > 0
    ) {
      if (Array.isArray(error.response.data.message))
        displayToast('ERROR', error.response.data.message[0]);
      else displayToast('ERROR', error.response.data.message);
    } else {
      displayToast('ERROR', error.message);
    }
  } catch (e) {
    displayToast('ERROR', error.message);
  }
  return {
    type: Type.UPDATE_FAQ_REQUEST_FAILURE,
    error: error,
  };
}
export function resetStateUpdateFaq() {
  return {
    type: Type.RESET_UPDATE_FAQ_STATE,
  };
}
