import { UserTypes as Type } from '../../../types/user';

const initialState = {
  successfullyChanged: null,
  loading: false,
  error: null,
};

const ResetPasswordReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case Type.GET_CHANGE_PASSWORD_REQUEST:
      return { ...state, loading: true,  successfullyChanged: null,error: null 
      };

    case Type.GET_CHANGE_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        successfullyChanged: action.payload,
        loading: false,
      };
    case Type.GET_CHANGE_PASSWORD_REQUEST_FAILURE:
      return { ...state, error: action.error, successfullyChanged: null, loading: false 
      };
    default:
      return state;
  }
};

export default ResetPasswordReducer;