import { AdminSettingTypes as Type } from '../../../types/adminsetting';

const initialState = {
  deleteAdmin: null,
  loading: false,
  error: null,
};

const DeleteAdminReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case Type.DELETE_ADMIN_REQUEST:
      return { ...state, loading: true, error: null, deleteAdmin: null };
    case Type.DELETE_ADMIN_REQUEST_SUCCESS:
      return {
        ...state,
        deleteAdmin: action.payload,
        loading: false,
      };
    case Type.DELETE_ADMIN_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };
      case Type.RESET_DELETE_ADMIN_STATE:
        return { ...initialState };
    default:
      return state;
  }
};

export default DeleteAdminReducer;