import { displayToast } from '../../../../helpers/notification';
import { CustomerReportsTypes as Type } from '../../../types/customerreport';

export function closeTicketRequest(body: any) {
  return {
    type: Type.CLOSED_CUSTOMER_REPORT_TICKET_REQUEST,
    body: body,
  };
}

export function closeTicketRequestSuccess(data: any) {
  return {
    type: Type.CLOSED_CUSTOMER_REPORT_TICKET_REQUEST_SUCCESS,
    payload: data,
  };
}

export function closeTicketRequestFailure(error: any) {
  try {
    if (
      error.response &&
      error.response.data &&
      error.response.data.message &&
      error.response.data.message.length > 0
    ) {
      if (Array.isArray(error.response.data.message))
        displayToast('ERROR', error.response.data.message[0]);
      else displayToast('ERROR', error.response.data.message);
    } else {
      displayToast('ERROR', error.message);
    }
  } catch (e) {
    displayToast('ERROR', error.message);
  }
  return {
    type: Type.CLOSED_CUSTOMER_REPORT_TICKET_REQUEST_FAILURE,
    error: error,
  };
}

export function resetStateCloseTicket() {
  return {
    type: Type.RESET_CLOSED_TICKET_STATE,
  };
}
