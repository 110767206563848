import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import LoginReducer from './user/login';
import ForgotPasswordReducer from './user/forgot_password';
import CheckTokenExpiryReducer from './user/check_expiry';
import EditItemReducer from './menu/update';
import uploadImageReducer from './upload-image';
import ResetPasswordReducer from './user/reset_password';
import MenuListReducer from './menu/list';
import FeaturedContentListReducer from './featuredcontent/list';
import CategoriesReducer from './menu/category-list';
import AddFeaturedContentReducer from './featuredcontent/add';
import CategoriesItemsReducer from './menu/item-list';
import MenuDataSyncReducer from './menu/syncMenuData';
import getConfigurationReducer from './configuration';
import UpdateFeaturedContentReducer from './featuredcontent/update';
import AdminListReducer from './adminsetting/list';
import AddNewAdminReducer from './adminsetting/add';
import UpdateAdminReducer from './adminsetting/update';
import ChangeAdminPasswordReducer from './adminsetting/changepassword';
import DeleteAdminReducer from './adminsetting/delete';
import DeleteFeaturedContentReducer from './featuredcontent/delete';
import AddFaqReducer from './faq/add';
import UpdateFaqReducer from './faq/update';
import TopicsFaqReducer from './faq/topics';
import StoreListReducer from './store/list';
import StoreCSVListReducer from './store/csv';
import ReferralListReducer from './referral';
import FAQListReducer from './faq/list';
import DeleteFaqReducer from './faq/delete';
import StoreEditReducer from './store/edit';
import StoreDetailReducer from './store';
import StoreDataSyncReducer from './store/syncStoreData';
import StoreMerchantAccountReducer from './store/createmerchantaccount';
import StoreRefreshMerchantReducer from './store/refreshstoremerchant';
import CustomerReportListReducer from './customerreport/list';
import ReOpenCustomerReporTicketReducer from './customerreport/reopenticket';
import CloseCustomerReporTicketReducer from './customerreport/closeticket';
import GCmembershipListReducer from './gc-membership';
import OrdersListReducer from './order/list';
import OrdersDetailReducer from './order';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'LoginReducer',
    'FeaturedContentListReducer',
    'getConfigurationReducer',
    'AdminListReducer',
    'MenuListReducer',
    'CustomerReportListReducer'
  ],
};

const rootReducers = combineReducers({
  LoginReducer,
  ForgotPasswordReducer,
  CheckTokenExpiryReducer,
  MenuListReducer,
  MenuDataSyncReducer,
  EditItemReducer,
  uploadImageReducer,
  FeaturedContentListReducer,
  CategoriesReducer,
  CategoriesItemsReducer,
  ResetPasswordReducer,
  AdminListReducer,
  AddFeaturedContentReducer,
  getConfigurationReducer,
  UpdateFeaturedContentReducer,
  AddNewAdminReducer,
  UpdateAdminReducer,
  ChangeAdminPasswordReducer,
  DeleteAdminReducer,
  DeleteFeaturedContentReducer,
  AddFaqReducer,
  UpdateFaqReducer,
  TopicsFaqReducer,
  StoreListReducer,
  StoreCSVListReducer,
  StoreMerchantAccountReducer,
  StoreRefreshMerchantReducer,
  ReferralListReducer,
  FAQListReducer,
  DeleteFaqReducer,
  StoreEditReducer,
  StoreDetailReducer,
  StoreDataSyncReducer,
  CustomerReportListReducer,
  ReOpenCustomerReporTicketReducer,
  CloseCustomerReporTicketReducer,
  GCmembershipListReducer,
  OrdersListReducer,
  OrdersDetailReducer,
});

const persistReducers = persistReducer(persistConfig, rootReducers);

export default persistReducers;
