import { takeEvery, put, call } from 'redux-saga/effects';
import { FeaturedContentTypes } from '../../../types/featuredcontent';
import {
  addFeaturedContentRequestFailure,
  addFeaturedContentRequestSuccess,
} from '../../../actions/featuredcontent/add'
import { addFeaturedContent } from '../../../../services/featuredcontent';

function* asyncAddFeaturedContentRequest(action: any): any {
  try {
    const response = yield call(
        addFeaturedContent,
        action.body
    );
    yield put(addFeaturedContentRequestSuccess(response));
  } catch (error) {
    yield put(addFeaturedContentRequestFailure(error));
  }
}

export function* addFeaturedContentSaga() {
  yield takeEvery(
    FeaturedContentTypes.ADD_FEATURED_CONTENT_REQUEST,
    asyncAddFeaturedContentRequest,
  );
}
