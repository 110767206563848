import { UserTypes as Type } from "../../../types/user";
import { displayToast } from '../../../../helpers/notification';


export function getLoginUserRequest(body: any) {
  return {
    type: Type.GET_LOGIN_REQUEST,
    body: body,
  };
}

export function getLoginUserRequestSuccess(data: any) {
  return {
    type: Type.GET_LOGIN_REQUEST_SUCCESS,
    payload: data,
  };
}

export function getLoginUserRequestFailure(error: any) {
  return {
    type: Type.GET_LOGIN_REQUEST_FAILURE,
    error: error,
  };
}

export function setLogoutRequest() {
  localStorage.removeItem('updatedName')
  return {
    type: Type.SET_LOGOUT_REQUEST
  };
}